import fetchIntercept from 'fetch-intercept';
import Config from '../config/development.js';
import { getAccessToken } from '../security/tokenHandler.js';

export const initializeFetchInterceptor = () => {
  fetchIntercept.register({
    request: async function (resource, config = {}) {
      
      const token = await getAccessToken();

      if (resource.includes(Config.REACT_APP_API_URL_BASE) || resource.includes(Config.REACT_APP_DOC_STREAM) || resource.includes(Config.REACT_APP_DOC_PRESIGNEDURL)) {
        config.headers = {
          ...config.headers,
          "authorizationToken": token
        };
      }

      return [resource, config];
    },

    requestError: function (error) {
      // Called when an error occurred during another 'request' interceptor call
      return Promise.reject(error);
    },

    response: function (response) {
      // Modify the response object
      return response;
    },

    responseError: function (error) {
      // Handle a fetch error
      return Promise.reject(error);
    },
  });
};
