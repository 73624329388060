import React from 'react';

import styles from '../modal.module.scss';

const ModalHeader = (props) => (
    <div className={styles['modal-header']}>
        <div className={styles['modal-title']}>{props.title}</div>
        <div >
            {
                props.title==='UDocs Help' ? 
                    <div className={styles['modal-link']}><a href='/UDocsHelp.mp4' target="_blank">UDocs Help Video</a></div>
                : null}
            <div className={styles['modal-close']} onClick={() => props.closeModal()}> </div>
        </div>
    </div>
)

export default ModalHeader;