import { gql } from '@apollo/client';

export const GET_ALERT = gql`
    query AlertQuery($manualCode: String) {
            alerts{
                getAlerts(manualCode: $manualCode) {
                    activeAlerts
                    {
                        alertDescription
                        alertId
                        alertTitle
                        alertType
                    }
                }
            }
        }
`;