import { ApolloClient, HttpLink } from '@apollo/client';
import config from '../config/development.js';
import { cache } from '../cache';
import { getAccessToken } from '../security/tokenHandler.js';

const customFetch = async (uri, options) => {
  const token = await getAccessToken();
  const { query, variables } = JSON.parse(options.body)

  const response = await fetch(`${config.REACT_APP_API_URL_BASE}${config.REACT_APP_GRAPHQL_URL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(token && { authorizationToken: token })
    },
    body: JSON.stringify({ query, variables }),
  });

  return response;
};

// Create an HttpLink using the custom fetch function
const httpLink = new HttpLink({
  uri: `${config.REACT_APP_API_URL_BASE}${config.REACT_APP_GRAPHQL_URL}`,
  fetch: customFetch,
});

// Initialize Apollo Client
const createApolloClient = new ApolloClient({
  link: httpLink,
  cache,
  connectToDevTools:true
});

export default createApolloClient;