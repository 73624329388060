import React, { useState, useEffect, useRef, useContext } from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import styles from "./header.module.scss";
import Icon from "../Icon/Icon";
import { Modal } from "../Modal/Modal";
import HelpLegend from "../HelpLegend/HelpLegend";
import { GET_EMP_PROFILE } from "../../operations/queries/preferences";
import axios from 'axios';
import { UserContext } from "../../containers/App/UserContext.js";
import config from '../../config/development.js';

const Header = () => {
  
  const dropdownRef = useRef(null);
  const history = useHistory();
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
  const [refreshed, setRefreshed] = useState(moment());
  const [openHelp, setOpenHelp] = useState(false);
  const [udocs, setUdocs] = useState("UDocs");
  const { userId, firstName, lastName } = useContext(UserContext);
  const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });

  useEffect(() => {
    if (config.ENV === 'stage')
      setUdocs("UDocs-Preprod");
    else if (config.ENV === 'qa')
      setUdocs("UDocs-QA");
    // else setUdocs("UDocs-Local");
  }, []);

  const onClick = () => {
    setIsActive(!isActive);
  };
  const onHandleLogout = (event) => {
    const idTokenValue = localStorage.getItem("idToken")

    const params = {
      token: idTokenValue,
      redirect_uri: config.redirectUri
    };

    // Call LogOut URL
    axios.get(`${config.logoutURL}${idTokenValue}`, { data: params })
      .then(res => {
        // Clear Storage
        localStorage.clear()
        localStorage.setItem("loggedOut", "true")

        window.location.href = config.redirectUri;
      })
      .catch(err => {
        console.log("error", err)
      })
  };

  return (
    <header className={styles.header}>
      <div className={styles.logo} onClick={() => history.push("/")}>
        <Icon variant="united-logo" width="161px" clickable="true" />
      </div>
      <div className={styles.title}>{udocs}</div>
      <div className={styles["refresh-profile-info"]}>
        {/* <div onClick={() => setRefreshed(moment())}><Icon variant='refresh' width='24px' clickable='true' /></div> */}
        <div className={styles.help} onClick={() => setOpenHelp(true)}>
          ?
        </div>

        <div className={styles.date}>
          <div>Last refreshed</div>
          <div>{refreshed.format("MM/DD/YYYY HH:mm")}</div>
        </div>
        <div className={styles.profile} onClick={onClick}>
          <div  className={styles.menutriger}>
            <div className={styles.head}></div>
            <div className={styles.body}></div>
          </div>
          <div>
            {profileinfo.error ? (
              <div></div>
            ) : profileinfo.loading ? null : (
              <div className={styles.name}>
                <div>
                  {lastName || "unknown"},
                </div>
                <div>
                  {firstName || "unknown"}
                </div>
              </div>
            )}
          </div>
          <nav
            ref={dropdownRef}
            className={isActive ? styles.menuactive : styles.menu}
          >
            <ul>
              <li>
                <div className={styles.signlabel}>
                  {profileinfo.error ? (
                    <div></div>
                  ) : profileinfo.loading ? null : (
                    <div className={styles.profileName}>
                      {lastName ||
                        "unknown"}
                      ,
                      {firstName ||
                        "unknown"}{" "}
                      {userId ||
                        "unknown"}{" "}
                    </div>
                  )}
                </div>
              </li>
              <li>
                <a onClick={onHandleLogout} className={styles.signlabel}>
                  Sign out
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {openHelp && (
        <Modal closeModal={() => setOpenHelp(false)} title="UDocs Help">
          <HelpLegend />
        </Modal>
      )}
    </header>
  );
};

export default Header;
