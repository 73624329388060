import React from 'react';

import styles from '../modal.module.scss';

const ModalBody = (props) => {
    return (
        <div className={styles['modal-body']}>
            {props.children}
        </div>
    )
}

export default ModalBody;