import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

import { HomeContext } from '../../containers/Home/HomeContext';
import { ViewerContext } from '../../containers/Viewer/ViewerContext';
import { PreviewContext } from '../../containers/PreviewManual/PreviewManualContext';
import cx from 'classnames';
import moment from 'moment';
import styles from './Alert.module.scss';
import { GET_RECENT_MANUALS, GET_FAVORITE_MANUALS } from '../../operations/queries/manuals';
import { mutations, UPDATE_EMP_FAV } from '../../operations/mutations';

const Alert = (props) => {
    const { alertTitle, alertType, alertDescription, isManualAlert } = props;
  /*  const recentManuals = useQuery(GET_RECENT_MANUALS);
    const favoriteManuals = useQuery(GET_FAVORITE_MANUALS);
    // const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });

    const { selectedPinManualCode, setSelectedPinManualCode } = useContext(HomeContext);
    const { setSelectedDocument, setHtml } = useContext(ViewerContext);
    const { upperClick, setUpperClick } = useContext(PreviewContext);

    const { selectManual, setRecent, setFavorites } = mutations;
    
    const history = useHistory();
    const location = useLocation();

    const handleViewManualClick = () => {
        const newrecent = { categoryName: category, manualName: title, manualCode: info.manualCode, revDate: info.revDate, revNumber: '',
                            typeInfo: info.fleetType || info.vendorName || info.engineType || null,
                            fleetType: info.fleetType || null, vendorName: info.vendorName || null, engineType: info.engineType || null };
        setSelectedDocument(null);
        selectManual(newrecent);
        setUpperClick(isUpperClick);
        setHtml(isHtmlManual);
        //console.log(newrecent);
        history.push('/PreviewManual');
    }
*/
    return (
        !isManualAlert ?
        <div className={styles.container}>
        <div className={cx(styles.alertBox, {[styles.alertI]: alertType == 'I'},{[styles.alertM]: alertType == 'M'}, {[styles.alertC]: alertType == 'C'}, {[styles.narrowBox]: alertDescription.length===0 })}>
            <div className={styles.title}>
                <div className={cx({[styles.itemI]: alertType == 'I'}, {[styles.itemM]: alertType == 'M'}, {[styles.itemM]: alertType == 'C'}, {[styles.itemC]: alertType == 'C'})}>
                    <div className={cx(styles.item,{[styles.itemEX]: alertType != 'I'})}>{alertType == 'I'? 'i' : '!'}</div>
                </div>
                <div className={cx({[styles.alertIColor]: alertType == 'I'}, {[styles.alertMColor]: alertType == 'M'}, {[styles.alertCColor]: alertType == 'C'})}>{alertTitle}</div>
            </div>
            {
                
            <div className={cx(styles.description, {[styles.alertCColor]: alertType == 'C'})}>
                <div>{alertDescription}</div>
            </div>
            }
       {/*     <div className={styles.info}>
                <div>{category === 'Fleet' ? info.fleetType : category === 'Vendor' ? info.vendorName : info.engineType}</div>
                <div>Prepublished</div>
                <div>{`REV Date: ${moment(info.revDate).format('MM/DD/YYYY')}`}</div>                
            </div>
            <div className={styles.footer}>
                <div className={styles.actions}>
                    <div className={cx(styles.statusholder, {[styles.approved]: info.previewStatus==='Approve'}, {[styles.pending]: info.previewStatus===null || info.previewStatus==='Pending'}, {[styles.denied]: info.previewStatus==='Reject'})}>
                        <div>{info.previewStatus===null ? 'Pending' : info.previewStatus}</div>
                    </div>
                    <div className={styles.manual} onClick={() => handleViewManualClick()}><div>Preview Manual</div><div className={styles.arrow}></div></div>
                </div>
    </div>*/}
        </div>
        </div>
        :
        null
    )
}

export default Alert;