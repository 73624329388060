import React, { useEffect, useState, useContext } from 'react';
import 'react-notifications-component/dist/theme.css'
import { datadogRum } from '@datadog/browser-rum';
import config  from '../../config/development.js';
import { PCKEVariables } from '../../utils/common.js';
import axios from 'axios';
import { UserContext } from './UserContext.js';
import { ApolloProvider } from '@apollo/client';
import App from './App.js';
import createApolloClient from '../../Interceptor/apolloClientInterceptor.js';
import { initializeFetchInterceptor } from '../../Interceptor/fetchInterceptor.js';
import { setTokenExpiry, setRefreshTokenExpiry } from '../../security/tokenHandler.js';
const randomstring = require("randomstring");
const hostname = window.location.hostname;

const AppWrapper = () => {
	const [oamAuthenticated, setOamAuthenticated] = useState(false);
    const [accessToken, setAccessToken] = useState("");
	const {setUserId, setFirstName, setLastName } = useContext(UserContext);
    const [client, setClient] = useState(null);

	useEffect(() => {
		// Below referrer logic is to handle when udocsweb launched from other websites(united airlines domain) (ex webtech)
		// storing the url to be navigated as referrer in local storage, after udocsweb login completed, redirection happens to the referrer url
		// localstorage referrer value is removed before redirection
		const referrer = document.referrer;
		let referrerDomain = "";

		if(referrer !== "")
		 referrerDomain = new URL(referrer).hostname;

		if (referrerDomain.includes(".ual.com") && referrerDomain !== "signon.ual.com" && referrerDomain !== hostname){
			console.log('setting Referrer');
			localStorage.setItem('Referrer', window.location.href);
		}

		if (window.location.hostname !== "localhost") {
		  if (!localStorage.getItem("oamAuthenticated")) {
			if (window.location.search.toString().includes("code")) {
			  if (!localStorage.getItem("loggedOut")) {
				const code_verifier = localStorage.getItem("code_verifier")
	
				const queryParams = new URLSearchParams(window.location.search);
				const authCode = queryParams.get("code");
	
				localStorage.setItem("authCode", authCode ? authCode : "")
	
				const params = new URLSearchParams();
				params.append("grant_type", config.grantType);
				params.append("redirect_uri", config.redirectUri);
				params.append("code", authCode ? authCode : "");
				params.append("code_verifier", code_verifier ? code_verifier : "")
	
				// Get Token details
				axios.post(config.tokenEndpoint, params, {
				  headers: {
					"X-OAUTH-IDENTITY-DOMAIN-NAME": `${config.domain}`,
					"Authorization": `${config.basicCode}`,
				  }
				})
				  .then((res) => {
					const token = {
					  accessToken: res.data.access_token,
					  idToken: res.data.id_token,
					}
					//console.log("res token endpoint:- ", res)
					localStorage.setItem("accessToken", res.data.access_token)
					localStorage.setItem("idToken", res.data.id_token)
					localStorage.setItem('refreshToken', res.data.refresh_token)
					localStorage.setItem("tokenStartTime", Date.now() + config.refreshTokenExpiresInHours * 60 * 60 * 1000);
					setTokenExpiry(res.data.access_token)
					// Get User Details
					axios.get(`${config.userInfo}`, {
					  headers: {
						"Authorization": "Bearer " + res.data.access_token,
					  }
					})
					  .then(info => {
						//console.log("Header.js -> user deatils:- ", info)
						localStorage.setItem("firstName", info.data.given_name)
						setFirstName(info.data.given_name)
						localStorage.setItem("lastName", info.data.family_name)
						setLastName(info.data.family_name)
						localStorage.setItem("id", info.data.sub)
						setUserId(info.data.sub)
						localStorage.setItem("loggedOut", "false")
						localStorage.setItem("oamAuthenticated","true");
						setOamAuthenticated(true);
					  })
					  .catch(err => {
						console.log("error", err)
					  })
				  })
				  .catch((err) => {
					console.log("token error catch", err);
				  })
			  }
			  else {
				localStorage.clear()
	
				window.location.href = config.redirectUri;
			  }
			}
			else {
			  // Get code_verifier and code_challenge
			  let code_challenge = ""
			  const { code_verifier, challengeIs } = PCKEVariables();
	
			  challengeIs.then(challenge => {
				localStorage.setItem("code_verifier", code_verifier)
				localStorage.setItem("code_challenge", challenge)
	
				code_challenge = challenge
	
				const stateVal = randomstring.generate(21);
	
				// Get Code URL
				const OAMAuthURL = `${config.authorizationEndpoint}?client_id=${config.client_id}&domain=${config.domain}&redirect_uri=${config.redirectUri}&response_type=${config.responseType}&scope=${config.scope}&state=${stateVal}&code_challenge_method=${config.codeChallengeMethod}&code_challenge=${code_challenge}`;
				//console.log("Header.js -> OAMAuthURL:- ", OAMAuthURL)
	
				const newWindow = window.open(OAMAuthURL, "_self", "noopener,noreferrer");
				if (newWindow) newWindow.opener = null;
			  })
			}
		  } else {
			initializeSession();
		  }
		}
		else {
			initializeSession();
		}
	  }, [oamAuthenticated])

    const initializeSession = () => {
        setOamAuthenticated(true);
        setAccessToken(localStorage.getItem("accessToken"));
        setClient(createApolloClient);
        setFirstName(localStorage.getItem("firstName"));
        setLastName(localStorage.getItem("lastName"));
        setUserId(localStorage.getItem("id"));
		initializeFetchInterceptor(localStorage.getItem("accessToken"))
		setRefreshTokenExpiry()

		if (localStorage.getItem('Referrer') !== null){
			console.log('clearing Referrer');
			const urlToRedirect = localStorage.getItem('Referrer'); 
			localStorage.removeItem('Referrer');
			console.log('urlToRedirect ' + urlToRedirect);
			window.location.href = urlToRedirect;
		}
    };
	
	

	const enableRum = () => {
		datadogRum.init({
		  applicationId: config.APPLICATION_ID,
		  clientToken: config.CLIENT_TOKEN,
		  site: 'datadoghq.com',
		  service: config.SERVICE_NAME,
		  env: config.ENV,
		  sessionSampleRate: 100,
		  sessionReplaySampleRate: 20,
		  trackUserInteractions: true,
		  trackResources: true,
		  trackLongTasks: true,
		  defaultPrivacyLevel: 'mask-user-input',
		})
		datadogRum.startSessionReplayRecording()
	  }

	useEffect(() => {
        if(!oamAuthenticated)
		enableRum()
	  }, [])


	return oamAuthenticated && accessToken ? (
        <ApolloProvider client={client}>
		    <App/>
        </ApolloProvider>
	) : null;
}

export default AppWrapper;