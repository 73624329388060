import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import cx from 'classnames';
import moment from 'moment';
import styles from './assignment.module.scss';
import Comments from './Comments/Comments';
import { mutations, UPDATE_EMP_FAV, UPDATE_MANUAL_REVIEWER } from '../../operations/mutations';
import { GET_MANUAL_REVIEWERS, GET_REVIEWER_ACTIVITIES, GET_PRE_ADMINS_IMAGE} from '../../operations/queries/admin';
import { PreviewContext } from '../../containers/PreviewManual/PreviewManualContext';


const Assignment = (props) => {
    const { info, isSimple, isAssign, showAttachment, setShowAttachment, setAttachment, setContentSelected, setSearchSelected} = props;
    const [removeReviewer, { data }] = useMutation(UPDATE_MANUAL_REVIEWER);
    const { selectManual, setRecent, setFavorites, setReviewers, setActivities } = mutations;
    const reviewers = useQuery(GET_MANUAL_REVIEWERS);
    const activities = useQuery(GET_REVIEWER_ACTIVITIES);
    const activityImage = useQuery(GET_PRE_ADMINS_IMAGE, {variables: {previewAdminActivityId: info.previewAdminActivityId ? info.previewAdminActivityId : 0}});
    const [imgBase64, setImageBase64] = useState('');
    const { previewAdminActivityId, setPreviewAdminActivityId, setPreviewAdminActivityImageId } = useContext(PreviewContext);

    useEffect(() => {
        console.log('from assignment',info.previewAdminActivityId, activityImage);
        if(activityImage && activityImage.data && activityImage.data.admin.getPreviewAdminActivityImage.length>0)
        {
            setPreviewAdminActivityImageId(activityImage.data.admin.getPreviewAdminActivityImage[0].previewAdminActivityImageId);
            setImageBase64(activityImage.data.admin.getPreviewAdminActivityImage[0].imgBase64);
        }
    }, [activityImage])

    const handleRemoveViewerClick = () => {
        const currentreviewer = [...reviewers.data.reviewer];
        const currentactivities = [...activities.data.activity];

        const index = currentreviewer.findIndex(f => f.manualCode === info.manualCode && f.employeeId === info.employeeId);
            if (index !== -1) currentreviewer.splice(index, 1); 
            
            removeReviewer({ variables: {
                "employeeId": info.employeeId,
                "manualCode": info.manualCode
            } 
        });
        setReviewers(currentreviewer);

        const activityIndex = currentactivities.findIndex(g => g.previewAdminId === info.previewAdminId);
        if (activityIndex !== -1) currentactivities.splice(activityIndex, 1); 
        setActivities(currentactivities);
    }
    //console.log('assignment page', info);
    return (
        <div>
            {isSimple ?
                <div className={styles.simplecontainer}>
                    <div className={styles.item}>
                        {info.manualCode.substring(1,3)}
                    </div>
                </div>
                : <div className={styles.container}>
                    <div className={styles.item}>
                        {info.firstName.substring(0,1) + info.lastName.substring(0,1)}
                    </div>
                    <div className={styles.detail}>
                        <div className={styles.itemlabel}>{info.firstName+' '+info.lastName}</div>
                        <div className={styles.datelabel}>{moment(info.modifiedDate).format('MM/DD/YYYY')}
                           { isAssign ? <div className={styles.removeicon} onClick={() => handleRemoveViewerClick()}><div className={styles.middleline}></div></div>
                                    : <div className={cx(styles.statusholder, {[styles.approved]: info.reviewActionDescription==='Approve'}, {[styles.pending]: info.reviewActionDescription==='Pending'}, {[styles.denied]: info.reviewActionDescription==='Reject'})}>
                            {info.reviewActionDescription}</div>
                            }
                        </div>                        
                        {
                            isAssign ? null : 
                            <>
                        <div className={styles.bottomlabel} onClick={() => setPreviewAdminActivityId(info.previewAdminActivityId)}>{info.comments && info.comments.length>88 ? info.comments.substring(0,88) + ' ...' : info.comments}</div>
                        {info.comments && info.comments.length>88 && info.previewAdminActivityId === previewAdminActivityId ? <Comments comments={info.comments} setShow={setPreviewAdminActivityId}/> : null}
                        { imgBase64 ? <div className={styles.hyperlinklabel} onClick={() => {setShowAttachment(!showAttachment);  setContentSelected(false); setSearchSelected(false); setAttachment(info.imgBase64 && info.imgBase64.length>0 ? info.imgBase64 : imgBase64); }}>View Attachment</div> : null}
                        </>
                        }
                    </div>                 
                </div>
            }
        </div>
    )
}

export default Assignment;