import React, { createContext, useState } from 'react';

export const UserContext = createContext();

const UserContextProvider = (props) => {
    const [userId, setUserId] = useState(localStorage.getItem("id"));
    const [firstName, setFirstName] = useState(localStorage.getItem("firstName"));
    const [lastName, setLastName] = useState(localStorage.getItem("lastName"));
    const [station, setStation] = useState('');

    return (
        <UserContext.Provider value={{userId, setUserId, firstName, setFirstName, lastName, setLastName, station, setStation}}>
            {props.children}
        </UserContext.Provider>
    )
}
export default UserContextProvider;