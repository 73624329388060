import React, { useEffect, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client'

import styles from './pins.module.scss';
import Icon from '../../Icon/Icon';
import { GET_EMP_PROFILE } from '../../../operations/queries/preferences';
import { UPDATE_EMP_MANUAL_PIN, mutations } from '../../../operations/mutations';
import { GET_MANUAL_PINS, GET_EMP_PINS } from '../../../operations/queries/manuals';

import { store } from 'react-notifications-component';
import Notification from '../../../components/Notification/Notification';
import 'react-notifications-component/dist/theme.css';
import { UserContext } from '../../../containers/App/UserContext';


const Pins = (props) => {
    const { manualCode, setSelectedDocument, isPreview, setViewerLoading, close, isHtmlManual } = props;
    const { userId } = useContext(UserContext);
    const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
            userId: userId
		}
	  });
    const emppininfo = useQuery(GET_EMP_PINS, {
        variables: { employeeId: userId || 'V726082', manualCode: manualCode }, fetchPolicy: 'network-only'});
    const pinsinfo = useQuery(GET_MANUAL_PINS);

    const { setPins } = mutations;
    const [updateManualPin] = useMutation(UPDATE_EMP_MANUAL_PIN);

    useEffect(() => {
        if (emppininfo.data && emppininfo.data.manuals.getPinnedDocuments) {
          //  debugger
            const pins = emppininfo.data.manuals.getPinnedDocuments.map(p => {
                return {
                    manualCode: manualCode, 
                    fileName: p.fileName,
                    title: p.title, 
                    subTitle: p.subTitle
                }
            });
            setPins(pins);
        }
    }, [emppininfo]);
    useEffect(() => {
        if (emppininfo.error) {
            store.addNotification({
				container: 'top-right',
				animationIn: ["animate__animated", "animate__fadeIn"],
				  animationOut: ["animate__animated", "animate__fadeOut"],
				  width: 500,
				dismiss: {
					duration: 400,
					onScreen: true
				},
				content: (
					<Notification title={'Pinned Documents failed to load.'} message={'Currently we are unable to retrieve your manuals please retry or email UDocs-App-Support@united.com.'} type={'failure'}/>
				),
				slidingExit: {
					duration: 800,
					timingFunction: 'ease-out',
					delay: 0
				},
				touchSlidingExit: {
					swipe: {
					  duration: 400,
					  timingFunction: 'ease-out',
					  delay: 0,
					},
					fade: {
					  duration: 400,
					  timingFunction: 'ease-out',
					  delay: 0
					}
				}
			});
        }
    }, [emppininfo.error]);

    const clearAllClick = () => {
        const currentpins = [...pinsinfo.data.pins];
        setPins([]);

    }
    const removePinClick = (fileName) => {
        const currentpins = [...pinsinfo.data.pins];
        const index = currentpins.findIndex(p => p.fileName === fileName);
        if (index !== -1) currentpins.splice(index, 1); 

        setPins(currentpins);
        updateManualPin({ variables: {
                employeeId: userId || 'V726082', 
                manualCode: manualCode, 
                fileName: fileName, 
                isActive: false
            } 
        });
    }

    console.log(pinsinfo)
    return (
        <div className={styles.pins}  style={{maxHeight: props.innerHeight}}>
            <div className={styles.heading}>
                <div>Documents</div>
                {/* <div onClick={() => clearAllClick()} className={styles.clear}>Clear All</div> */}
            </div>
            <div className={styles['pinned-docs']}>
                {
                    pinsinfo.data && pinsinfo.data.pins.length > 0 ? 
                        pinsinfo.data.pins.map((p, i) => {
                            return (
                                <div key={i} className={styles.doc}>
                                    <div className={styles.title} onClick={() => {setViewerLoading(true); setSelectedDocument(p); close();}}>
                                        <div>{p.title}</div>
                                        <div className={styles.subTitle}>{p.subTitle}</div>
                                    </div>
                                    <div className={styles.actions}>
                                        {
                                            isHtmlManual ? 
                                            <div onClick={() => window.open(`/udocs/htmlviewer?manualCode=${p.manualCode}&fileName=${p.fileName}&isPreview=${isPreview}&title=${p.title}`, '_blank')}><Icon variant='popout' /></div>
                                            :
                                            <div onClick={() => window.open(`/udocs/pdfviewer?manualCode=${p.manualCode}&fileName=${p.fileName}&isPreview=${isPreview}&title=${p.title}`, '_blank')}><Icon variant='popout' /></div>
                                        }
                                        <div onClick={() => removePinClick(p.fileName)} className={styles.remove}></div>
                                    </div>
                                </div>
                            )
                        })
                    :
                        <div className={styles.doc}>Currently there are no pins for this manual.</div>
                }
            </div>
        </div>
    )
}

export default Pins;