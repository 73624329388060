import { gql } from '@apollo/client';

import { currentManualVar, manualsVar, currentSearchVar, currentRecentVar, currentFavoriteVar, currentPinsVar, currentReviewerVar, currentActivityVar } from '../../cache';
import setSelectedManual from './setSelectedManual/setSelectedManual';
import setSearchManuals from './setSearchManuals/setSearchManuals';
import setRecentManuals from './setRecentManuals/setRecentManuals';
import setFavoriteManuals from './setFavoriteManuals/setFavoriteManuals';
import setManualPins from './setManualPins/setManualPins';
import setReviewer from './setReviewer/setReviewer';
import setActivity from './setActivities/setActivities';

export const mutations = {
    selectManual: setSelectedManual(manualsVar, currentManualVar),
    selectSearch: setSearchManuals(currentSearchVar),
    setRecent: setRecentManuals(currentRecentVar),
    setFavorites: setFavoriteManuals(currentFavoriteVar),
    setPins: setManualPins(currentPinsVar),
    setReviewers: setReviewer(currentReviewerVar),
    setActivities: setActivity(currentActivityVar),
}

export const UPDATE_EMP_FAV = gql`
    mutation($employeeId: String!, $manualCode: String!, $isActive: Boolean!) {
        userPreferences {
            addFavouriteManual(
                userfavourite: {
                employeeId: $employeeId
                manualCode: $manualCode
                isActive: $isActive
                }
            ) {
                manualCode
            }
        }
    }
`;
export const UPDATE_EMP_MANUAL_PIN = gql`
    mutation(
        $employeeId: String!
        $manualCode: String!
        $fileName: String!
        $isActive: Boolean!
    ) {
        userPreferences {
            addManualPins(
                employeeId: $employeeId
                manualCode: $manualCode
                fileName: $fileName
                isActive: $isActive
            )
        }
    }
`;
export const UPDATE_MANUAL_REVIEWER = gql`
    mutation(
        $employeeId: String!
        $manualCode: String!
    ) {
        admin {
            removePreviewAdmin(
                employeeId: $employeeId
                manualCode: $manualCode
            )
        }
    }
`;
export const UPDATE_MANUAL_VERSION = gql`
    mutation(        
        $manualCode: String!
        $isApproved: Boolean!
        $firstName: String!
        $lastName: String!
    ) {
        admin {
            updateManualVersion(
                manualCode: $manualCode
                isApproved: $isApproved
                firstName: $firstName
                lastName: $lastName
            )
        }
    }
`;
export const UPDATE_PRE_ADMIN_ACTIVITY = gql`
    mutation(
        $comments: String!
        $imgBase64: String!
        $manualCode: String!
        $previewAdminActivityId: Int!
        $employeeId: String!
        $reviewActionId: Int
        $previewAdminActivityImageId: Int!
        $firstName: String!
        $lastName: String!
    ) {
        admin {
            savePreviewAdminActivity(
                comments: $comments
                imgBase64: $imgBase64
                manualCode: $manualCode
                previewAdminActivityId: $previewAdminActivityId
                previewAdminActivityImageId: $previewAdminActivityImageId
                employeeId: $employeeId
                reviewActionId: $reviewActionId
                firstName: $firstName
                lastName: $lastName
            )
        }
    }
`;
export const UPDATE_SEL_MANUAL = gql`
mutation ($employeeId: String!, $manualCode: String!, $isPreview: Boolean!,$adminManualId:Int, $isFromUDocs:Boolean!) {
    manuals {
      getManual(
        employeeId: $employeeId
        manualCode: $manualCode
        isPreview: $isPreview
        adminManualId: $adminManualId
        isFromUDocs:$isFromUDocs
      ) {
        title
        fileName
        hasChildren
        hasDocument
        isHtml
        isDocumentCollection
        subTree {
          title
          subTitle
          fileName
          adminManualId
          isSsi
          subTree {
            title
            subTitle
            fileName
            adminManualId
            isSsi
            subTree {
              title
              subTitle
              fileName
              adminManualId
              isSsi
              subTree {
                title
                subTitle
                fileName
                adminManualId
                isSsi
                subTree {
                  title
                  subTitle
                  fileName
                  adminManualId
                  isSsi
                  subTree {
                    title
                    subTitle
                    fileName
                    adminManualId
                    isSsi
                    __typename
                  }
                  __typename
                }
                __typename
              }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;