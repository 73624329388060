import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation,useLazyQuery } from '@apollo/client';

import { HomeContext } from '../../containers/Home/HomeContext';
import { ViewerContext } from '../../containers/Viewer/ViewerContext';
import { PreviewContext } from '../../containers/PreviewManual/PreviewManualContext';
import cx from 'classnames';
import moment from 'moment';
import styles from './admintile.module.scss';
import { GET_RECENT_MANUALS, GET_FAVORITE_MANUALS,GET_MANUAL_VERSION_ACTIVITY } from '../../operations/queries/manuals';
import { mutations, UPDATE_EMP_FAV } from '../../operations/mutations';

const AdminTile = (props) => {
    const { title, info, category, isUpperClick, isHtmlManual, isSearchEnable,isDocumentCollection,manualCode } = props;
    const [refreshDate,setRefreshDate]=useState(null)
    const recentManuals = useQuery(GET_RECENT_MANUALS);
    const favoriteManuals = useQuery(GET_FAVORITE_MANUALS);
    /* const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
            userId: userId
          }
        });*/

    const { selectedPinManualCode, setSelectedPinManualCode } = useContext(HomeContext);
    const { setSelectedDocument, setHtml } = useContext(ViewerContext);
    const { upperClick, setUpperClick, isSearchEnabled, setSearchEnabled } = useContext(PreviewContext);

    const { selectManual, setRecent, setFavorites } = mutations;

    const [getVersionActivity,{loading , error, data:activityData}]=useLazyQuery(GET_MANUAL_VERSION_ACTIVITY)
    
    useEffect(()=>{
        if(isDocumentCollection){
            getVersionActivity({variables:{manualCode:manualCode,isPreview:true  },fetchPolicy: 'network-only'})   
        }
    },[isDocumentCollection])
    useEffect(()=>{
        if(activityData&&activityData.manualVersionActivity.latestRefreshDate){
            setRefreshDate(activityData.manualVersionActivity.latestRefreshDate.map(m=>m.modifiedDate))
        }

    },[activityData])
    
    const history = useHistory();
    const location = useLocation();

    const handleViewManualClick = () => {
        const newrecent = { categoryName: category, manualName: title, manualCode: info.manualCode, hasAlerts: false, revDate: info.revDate, revNumber: '',
                            uRL: null, typeInfo: info.fleetType || info.vendorName || info.engineType || null,
                            fleetType: info.fleetType || null, vendorName: info.vendorName || null, engineType: info.engineType || null };
        setSelectedDocument(null);
        selectManual(newrecent);
        setUpperClick(isUpperClick);
        setHtml(isHtmlManual);
        //console.log("searchEanble",isSearchEnable);
        setSearchEnabled(isSearchEnable);
        history.push('/PreviewManual');
    }

    return (
        <div className={styles.container}>
            <div className={styles.heading}>
                <div>{title}</div>
            </div>
            <div className={styles.info}>
            {(!isDocumentCollection)?<div>{`REV Date: ${moment(info.revDate).format('MM/DD/YYYY')}`}</div>:(refreshDate!==null)?<div>Last Updated On: {new Date(refreshDate).toLocaleDateString()}</div>: <div></div> }
                <div>Prepublished</div>
                <div>{category === 'Fleet' ? info.fleetType : category === 'Vendor' ? info.vendorName : info.engineType}</div>
            </div>
            <div className={styles.footer}>
                <div className={styles.actions}>
                    <div className={cx(styles.statusholder, {[styles.approved]: info.previewStatus==='Approve'}, {[styles.pending]: info.previewStatus===null || info.previewStatus==='Pending'}, {[styles.denied]: info.previewStatus==='Reject'})}>
                        <div>{info.previewStatus===null ? 'Pending' : info.previewStatus}</div>
                    </div>
                    <div className={styles.manual} onClick={() => handleViewManualClick()}><div>Preview Manual</div><div className={styles.arrow}></div></div>
                </div>
            </div>
        </div>
    )
}

export default AdminTile;