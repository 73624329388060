import React from 'react';
import Carousel from 'react-elastic-carousel';
import cx from 'classnames';

import styles from './helplegend.module.scss';
import Icon from '../Icon/Icon';

const HelpLegend = () => {
    return (
        <div className={styles['help-container']}>
            <Carousel itemsToShow={1}>
                <div className={styles.legend}>
                    <div className={styles.title}><div>Legend</div></div>
                    <div className={styles.body}>
                        <div className={styles.section}>
                            <div className={styles.title}>Main Navigation</div>
                            <div className={styles.item}>
                                <div><Icon variant='home' width='24px' clickable='false'/></div>
                                <div>Home</div>
                            </div>
                            {/* <div className={styles.item}>
                                <div></div>
                                <div>Manual Search</div>
                            </div> */}
                            <div className={styles.item}>
                                <div><Icon variant='recent' width='24px' clickable='false' primarycolor='#666666' /></div>
                                <div>Recently Viewed</div>
                            </div>
                            <div className={styles.item}>
                                <div><Icon variant='favorite' width='24px' clickable='false'/></div>
                                <div>Favorite Manuals</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.information}>i</div>
                                <div className={styles['info-label']}>Additional Resources</div>
                            </div>
                        </div>
                        <div className={styles.section}>
                            <div className={styles.title}>Viewing a Document</div>
                            <div className={styles.item}>
                                <div className={styles.colored}><Icon variant='favorite' width="26px" height="25px"/></div>
                                <div>Manual is marked as Favorite</div>
                            </div>
                            <div className={styles.item}>
                                <div className={styles.colored}><Icon variant='unfavorite' width="26px" height="25px"/></div>
                                <div>Manual isn't marked as Favorite</div>
                            </div>
                            <div className={styles.item}>
                                <div><Icon variant='unpinned' width='24px' height='23px' /></div>
                                <div>Pin this Document</div>
                            </div>
                            <div className={styles.item}>
                                <div><Icon className={styles['pinned-item']} variant='pinned' width='24px' height='23px' /></div>
                                <div>Document is Pinned</div>
                            </div>
                            <div className={styles.item}>
                                <div><Icon variant='popout' /></div>
                                <div>Pop Out Document in new window</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.legend}>
                    <div className={styles.title}><div>Manual Tiles</div></div>
                    <div className={styles.body}>
                        <Icon variant='legend' width='100%' height='100%' />
                    </div>
                </div>
                <div className={styles.legend}>
                    <div className={styles.title}><div>Document Viewer</div></div>
                    <div className={styles.body}>
                        <div><img src='/pdf-viewer.png' alt='document viewer' width='100%' /></div>
                    </div>
                </div>
                <div className={styles.legend}>
                    <div className={styles.title}><div>Alerts</div></div>
                    <div className={styles.alertbody}>
                        <div className={styles.alerttitle}>Informational</div>
                        <div className={cx(styles.alertBox, styles.alertI)}>
                            <div className={styles.title}>
                                <div className={styles.itemI}>
                                    <div className={cx(styles.item)}>i</div>
                                </div>
                                <div className={styles.alertIColor}>XXX Manual Moving to Boeing ToolBox</div>
                            </div>
                            <div className={cx(styles.description)}>
                                <div>Effective DD/MM/YYYY the “Some Manual Title” will migrated to Boeing Tool Box. User will need to access this manual from their My Boeing Fleet account after this date. Manual access permissions will be retained, however please contact this person this.person@email.com for assistance if you are unable to access via My Boeing Fleet after DD/MM/YYYY</div>
                            </div>
                        </div>
                        <div className={styles.alerttitle}>Moderate</div>
                        <div className={cx(styles.alertBox, styles.alertM)}>
                            <div className={styles.title}>
                                <div className={styles.itemM}>
                                    <div className={cx(styles.item, styles.itemEX)}>!</div>
                                </div>
                                <div className={styles.alertMColor}>XXX Manual Moving to Boeing ToolBox</div>
                            </div>
                            <div className={cx(styles.description)}>
                                <div>Effective DD/MM/YYYY the “Some Manual Title” will migrated to Boeing Tool Box. User will need to access this manual from their My Boeing Fleet account after this date. Manual access permissions will be retained, however please contact this person this.person@email.com for assistance if you are unable to access via My Boeing Fleet after DD/MM/YYYY</div>
                            </div>
                        </div>
                        <div className={styles.alerttitle}>Critical</div>
                        <div className={cx(styles.alertBox, styles.alertC)}>
                            <div className={styles.title}>
                                <div className={cx(styles.itemM, styles.itemC )}>
                                    <div className={cx(styles.item, styles.itemEX)}>!</div>
                                </div>
                                <div className={styles.alertCColor}>XXX Manual Moving to Boeing ToolBox</div>
                            </div>
                            <div className={cx(styles.description,styles.alertCColor)}>
                                <div>Effective DD/MM/YYYY the “Some Manual Title” will migrated to Boeing Tool Box. User will need to access this manual from their My Boeing Fleet account after this date. Manual access permissions will be retained, however please contact this person this.person@email.com for assistance if you are unable to access via My Boeing Fleet after DD/MM/YYYY</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.legend}>
                    <div className={styles.title}><div>Viewing Documents in Manuals</div></div>
                    <div className={styles.body}>
                            <div className={styles.sectiontitle}>Actions in Documents (HTML)</div>

                        <div><img src='/view-document.png' alt='view document' width='100%' /></div>
                    </div>
                </div>
                <div className={styles.legend}>
                    <div className={styles.title}><div>Advanced Search</div></div>
                    <div className={styles.body}>
                        <div><img src='/UDocs-Legend-6.svg' alt='advanced search' width='100%' /></div>
                    </div>
                </div>
                <div className={styles.legend}>
                    <div className={styles.title}><div>Advanced Search</div></div>
                    <div className={styles.body}>
                        <div><img src='/UDocs-Legend-7.svg' alt='advanced search' width='100%' /></div>
                    </div>
                </div>
            </Carousel>
        </div>
    )
}

export default HelpLegend;