import React from 'react';
import styles from './notification.module.scss';
import Icon from '../Icon/Icon'

const Notification = (props) => {
    const { title, message, type } = props;

    return(
        <div className={styles['notification-custom-background']}>
            <div className={`${styles['notification-custom-container']} ${styles['notification-custom-failure']}`}>
                <div className={styles['notification-custom-image-container']}>
                    <Icon className={styles['notification-custom-image']}
                        variant={type.toString().toLowerCase() === 'success' ? 'check' : 'alert'}
                        width="25px"
                        height="25px"
                        clickable='false'
                    />
                </div>
                <div className={styles['notification-msg-section']}>
                    <div className={styles['notification-custom-title']}>{title}</div>
                    <div className={styles['notification-custom-message']}>
                        {message}
                    </div>
                </div>
                <div><button className={styles['notification-custom-close']}>&#10005;</button></div>
            </div>
        </div>
    )
}

export default Notification;