import React, { createContext, useState } from 'react';

export const PreviewContext = createContext();

const PreviewContextProvider = (props) => {
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [isViewerLoading, setViewerLoading] = useState(false);
    const [manualAdmin, setManualAdmin]=useState([]);
    const [upperClick, setUpperClick]=useState([]);
    const [userComments, setUserComments]=useState([]);
    const [previewAdminActivityId, setPreviewAdminActivityId]=useState(null);
    const [previewAdminActivityImageId, setPreviewAdminActivityImageId]=useState(0);
    const [isSearchEnabled, setSearchEnabled] = useState(true);

    return (
        <PreviewContext.Provider value={{selectedDocument, setSelectedDocument, isViewerLoading, setViewerLoading, manualAdmin, setManualAdmin, upperClick, setUpperClick, previewAdminActivityId, setPreviewAdminActivityId, previewAdminActivityImageId, setPreviewAdminActivityImageId, isSearchEnabled, setSearchEnabled}}>
            {props.children}
        </PreviewContext.Provider>
    )
}
export default PreviewContextProvider;