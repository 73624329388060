import { useRef, useEffect } from 'react';
import moment from 'moment';

export const useEventListener = (eventName, handler, element = window) => {
    // Create a ref that stores handler
    const savedHandler = useRef();
    
    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);
  
    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On 
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;
            
            // Create event listener that calls handler function stored in ref
            const eventListener = event => savedHandler.current(event);
            
            // Add event listener
            element.addEventListener(eventName, eventListener);
            
            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
};

export const useItemsFilter = (items, filterValue, isItemsObject, objectFieldName) => {
    if (!filterValue) return items;

    return isItemsObject ? 
            items.filter(i => i[objectFieldName].toLowerCase().includes(filterValue.toLowerCase())) : 
            items.filter(i => i.toLowerCase().includes(filterValue.toLowerCase()));
}
export const useItemsSort = (items, valueType, isAsc, isItemsObject, objectFieldName) => {
    if (!valueType) return items;

    if (isItemsObject) {
        if (valueType === 'string') return isAsc ? [...items].sort((a, b) => (a[objectFieldName] > b[objectFieldName] ? 1 : -1)) : 
                                                   [...items].sort((a, b) => (b[objectFieldName] > a[objectFieldName] ? 1 : -1));
        if (valueType === 'date') return isAsc ? [...items].sort((a, b) => moment(a[objectFieldName]).diff(b[objectFieldName])) : 
                                                 [...items].sort((a, b) => moment(b[objectFieldName]).diff(a[objectFieldName]));
    }
    else {
        if (valueType === 'string') return isAsc ? [...items].sort((a, b) => (a > b ? 1 : -1)) : [...items].sort((a, b) => (b > a ? 1 : -1));
        if (valueType === 'date') return isAsc ? [...items].sort((a, b) => moment(a).diff(b)) : [...items].sort((a, b) => moment(b).diff(a));
    }
    return items;
}