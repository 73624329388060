import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { HomeContext } from '../Home/HomeContext';
import styles from './favorites.module.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import LayoutToggle from '../../components/LayoutToggle/LayoutToggle';
import SearchFilters from '../../components/SearchFilters/SearchFilters';
import DocTile from '../../components/DocTile/DocTile';
import { GET_EMP_MANUALS, GET_FAVORITE_MANUALS } from '../../operations/queries/manuals';
import { useItemsFilter, useItemsSort } from '../../Hooks/hooks';
import { GET_EMP_PROFILE } from '../../operations/queries/preferences';
import DocTable from '../../components/DocTable/DocTable';
import { UserContext } from '../App/UserContext';

const Favorites = () => {
    const { userId } = useContext(UserContext);
    const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });
    const [getEmpManuals, { loading, data }] = useLazyQuery(GET_EMP_MANUALS);
    const favoriteResults = useQuery(GET_FAVORITE_MANUALS);

    const { setSelectedPinManualCode, setSelectedAlertManualCode, showGrid, setShowGrid } = useContext(HomeContext);

    const [manuals, setManuals] = useState([]);
    const [search, setSearch] = useState('');
    const [sortopt, setSortopt] = useState('TA');
    //const [showGrid, setShowGrid] = useState(true);
    const [isMobile, setisMobile] = useState(false);
    const handleWindowSizeChange = () => { 
        if (window.innerWidth <= 768) { setisMobile(true); } else {setisMobile(false);}
    }

    useEffect(() => { setSelectedPinManualCode(null); setSelectedAlertManualCode(null); }, []);
    useEffect(() => {
        if (profileinfo.data && profileinfo.data.profileQuery) {
            getEmpManuals({ variables: { employeeId: userId || 'V726082' } });
        }
    }, [profileinfo])
    useEffect(() => {
        const manuals = data ? data.manuals : null;
        const favorites = favoriteResults && favoriteResults.data ? favoriteResults.data.favorites : null;
        if (manuals && favorites) {
            const fullfavs = [];
            favorites.forEach(f => {
                const foundcategory = manuals.getManualsForEmployee.find(m => m.fleetManuals.findIndex(fm => fm.manualCode === f.manualCode) !== -1 ||
                    m.vendorManuals.findIndex(vm => vm.manualCode === f.manualCode) !== -1 ||
                    m.engineManuals.findIndex(em => em.manualCode === f.manualCode) !== -1);
                
                const foundmanual = foundcategory && foundcategory.categoryName === 'Fleet' ? foundcategory.fleetManuals.find(fm => fm.manualCode === f.manualCode) :
                                    foundcategory && foundcategory.categoryName === 'Vendor' ? foundcategory.vendorManuals.find(vm => vm.manualCode === f.manualCode) :
                                    foundcategory && foundcategory.categoryName === 'Engine' ? foundcategory.engineManuals.find(em => em.manualCode === f.manualCode) : null;
                if (foundmanual) {
                    fullfavs.push({
                        categoryName: foundcategory.categoryName,
                        manualName: foundcategory.manualName,
                        manualCode: f.manualCode,
                        isHtml: foundcategory.isHtml,
                        isSearchEnabled: foundcategory.isSearchEnabled,
                        hasPins: foundmanual.hasPins,
                        hasAlerts: foundmanual.hasAlerts,
                        uRL: foundmanual.uRL,
                        revDate: foundmanual.revDate,
                        revision: foundmanual.revision,
                        typeInfo: foundmanual.fleetType || foundmanual.vendorName || foundmanual.engineType || null,
                        fleetType: foundmanual.fleetType,
                        vendorName: foundmanual.vendorName,
                        engineType: foundmanual.engineType
                    })
                }
            });
            setManuals(fullfavs);
        }
    }, [data, favoriteResults])
    useEffect(()=> {       
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }    
    }, []);

    const filterItems = (e) => setSearch(e.target.value);
    const filteredtiles = useItemsSort(
        useItemsFilter(manuals, search, true, 'manualName'),
        sortopt === 'TA' || sortopt === 'TZ' ? 'string' : 'date',
        sortopt === 'TZ' ? false : true, true,
        sortopt === 'TA' || sortopt === 'TZ' ? 'manualName' : 'revDate');
    return (
        <div>
            <div className={styles.container}>
                <PageTitle title='Favorites' />
                <SearchFilters filterItems={filterItems} sortItems={setSortopt} />
                { isMobile ? null : <LayoutToggle layout={showGrid} setLayout={setShowGrid}/>}  
                { isMobile ? <div className={styles.tiles}>
                    {filteredtiles.map((t, index) => <DocTile key={index} empId={userId || 'V726082'} title={t.manualName} info={t} category={t.categoryName} isFav={true} isHtmlManual={t.isHtml} isSearchEnable={t.isSearchEnabled}/>)}
                    </div> : showGrid ? <DocTable empId={userId || 'V726082'} rowData={filteredtiles} isFav={favoriteResults.data.favorites}/> : <div className={styles.tiles}>
                                {filteredtiles.map((t, index) => <DocTile key={index} empId={userId || 'V726082'} title={t.manualName} info={t} category={t.categoryName} isFav={true}  isHtmlManual={t.isHtml} isSearchEnable={t.isSearchEnabled}/>)}
                                </div>
                }            
                
                              
            </div>

        </div>
    )
}

export default Favorites;