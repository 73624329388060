import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { ViewerContext } from '../../../containers/Viewer/ViewerContext';

import styles from './pinitems.module.scss';
import Icon from '../../Icon/Icon';
import { GET_EMP_PINS } from '../../../operations/queries/manuals';
import { mutations } from '../../../operations/mutations';

import { store } from 'react-notifications-component';
import Notification from '../../../components/Notification/Notification';
import 'react-notifications-component/dist/theme.css'

const PinItems = (props) => {
    const { title, info, category, setShow, manualCode, empId,isHtmlManual, isSearchEnable } = props;
    const history = useHistory();
    const { selectManual } = mutations;
    const { setViewerLoading, setSelectedDocument, setHtml, setSearchEnabled } = useContext(ViewerContext);

    const { loading, error, data } = useQuery(GET_EMP_PINS, 
        {variables: { employeeId: empId || 'V726082', manualCode: manualCode }, fetchPolicy: 'network-only'});

    useEffect(() => {
        if (error) {
            store.addNotification({
				container: 'top-right',
				animationIn: ["animate__animated", "animate__fadeIn"],
				  animationOut: ["animate__animated", "animate__fadeOut"],
				  width: 500,
				dismiss: {
					duration: 400,
					onScreen: true
				},
				content: (
					<Notification title={'Pinned Documents failed to load.'} message={'Currently we are unable to retrieve your manuals please retry or email UDocs-App-Support@united.com.'} type={'failure'}/>
				),
				slidingExit: {
					duration: 800,
					timingFunction: 'ease-out',
					delay: 0
				},
				touchSlidingExit: {
					swipe: {
					  duration: 400,
					  timingFunction: 'ease-out',
					  delay: 0,
					},
					fade: {
					  duration: 400,
					  timingFunction: 'ease-out',
					  delay: 0
					}
				}
			});
        }
    }, [error]);

    const handleTitleClick = (fileName) => {
        selectManual({ categoryName: category, manualName: title, manualCode: info.manualCode, hasAlerts: info.hasAlerts, revDate: info.revDate, revNumber: info.revision ? info.revision : '',
            typeInfo: info.fleetType || info.vendorName || info.engineType || null,
            fleetType: info.fleetType || null, vendorName: info.vendorName || null, engineType: info.engineType || null });
        setViewerLoading(true);
        setSelectedDocument({ fileName: fileName });
        setHtml(isHtmlManual);
        setSearchEnabled(isSearchEnable);
        history.push('/viewer');
    }
    return (
        <div className={styles['pin-items-container']}>
            <div className={styles.heading}>
                <div>Pinned Documents</div>
                <div onClick={() => setShow(null)} className={styles.close}></div>
            </div>
            <div className={styles.body}>
                {
                    loading ? 
                    <div className={`${styles['body-spinner-container']} ${styles['spinner-section']}`}>
                        <div className={styles.spinner}></div>
                    </div>
                    :
                    data && data.manuals.getPinnedDocuments && data.manuals.getPinnedDocuments.length > 0 ? 
                        data.manuals.getPinnedDocuments.map((p, i) => {
                            return (
                                <div key={i} className={styles.doc}>
                                    <div onClick={() => handleTitleClick(p.fileName)}>
                                        <div className={styles.title}>{p.title}</div>
                                        <div className={styles.subtitle}>{p.subTitle}</div>
                                    </div>
                                    {
                                        isHtmlManual ? 
                                        <div onClick={() => window.open(`/udocs/htmlviewer?manualCode=${manualCode}&fileName=${p.fileName}&isPreview=${false}&title=${p.title}`, '_blank')}><Icon variant='popout' /></div>
                                        :
                                        <div onClick={() => window.open(`/udocs/pdfviewer?manualCode=${manualCode}&fileName=${p.fileName}&isPreview=${false}&title=${p.title}`, '_blank')}><Icon variant='popout' /></div>
                                        
                                    }
                                </div>
                            )
                        })
                    :
                    <div className={styles.doc}>Currently there are no pins for this manual.</div>
                }
            </div>
        </div>
    )
}

export default PinItems;