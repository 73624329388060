import { encode as base64encode } from "base64-arraybuffer";
var randomstring = require("randomstring");

export const PCKEVariables = () => {
    const code_verifier = randomstring.generate(128)

    async function generateCodeChallenge(codeVerifier) {
        const encoder = new TextEncoder();
        const data = encoder.encode(codeVerifier);
        const digest = await window?.crypto?.subtle?.digest("SHA-256", data);
        const base64Digest = base64encode(digest);
        // you can extract this replacing code to a function
        return base64Digest
            .replace(/\+/g, "-")
            .replace(/\//g, "_")
            .replace(/=/g, "");
    }

    const challengeIs = generateCodeChallenge(code_verifier).then(challenge => {
        //console.log("common.js -> code_verifier:- ", code_verifier);
        //console.log("common.js -> code_challenge:- ", challenge);
        return challenge
    });

    const pkceOBJ = {
        code_verifier,
        challengeIs
    }
    return pkceOBJ
}