import React,  { useState } from 'react';
import cx from 'classnames';
import styles from './layout.module.scss';

const LayoutToggle = (props) => {
    const { layout, setLayout } = props;
    return (
        <div className={styles.toggleIcon}>
            <div className={cx(styles.gridiconVisible, {[styles.gridiconInactive]: !layout}) } onClick={() => layout ? setLayout(layout):setLayout(!layout) }><span></span></div>
            <div className={cx(styles.tileiconInVisible, {[styles.tileiconActive]: !layout})} onClick={() => layout ? setLayout(!layout):setLayout(layout)}>
                <div className={styles.griditem}>1</div>
                <div className={styles.griditem}>1</div>
                <div className={styles.griditem}>1</div>  
                <div className={styles.griditem}>1</div>
            </div>
        </div>
        
    )
}

export default LayoutToggle;