import React, { useState, useContext } from 'react';
import { useLazyQuery, useQuery, useMutation  } from '@apollo/client';

import styles from './search.module.scss';
import Icon from '../../Icon/Icon';
import { GET_CONFIG_DRAWINGS, GET_SEARCH_FIELDS } from '../../../operations/queries/manuals';
import { GET_EMP_PROFILE } from '../../../operations/queries/preferences';
import { UPDATE_EMP_MANUAL_PIN, UPDATE_SEL_MANUAL, mutations } from '../../../operations/mutations';
import { GET_MANUAL_PINS, GET_EMP_PINS } from '../../../operations/queries/manuals';
import { UserContext } from '../../../containers/App/UserContext';

const Search = (props) => {
    const { userId } = useContext(UserContext);
    const { manualCode, isPreview, setSelectedDocument, setViewerLoading, close, isHtmlManual } = props;
    const [params, setParams] = useState([]);
    const [noResult, setNoResult] = useState(true);
    const { setPins } = mutations;

    // const tablecontents = useQuery(GET_TABLE_CONTENTS);
    const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });
    const pinsinfo = useQuery(GET_MANUAL_PINS);
    const [updateManualPin] = useMutation(UPDATE_EMP_MANUAL_PIN);
    const [getPins, { loading: pinsloading, data: pinsdata }] = useLazyQuery(GET_EMP_PINS, { fetchPolicy: 'network-only' });

    const [getConfigDrawings, { loading, data }] = useLazyQuery(GET_CONFIG_DRAWINGS, {
        fetchPolicy: 'network-only',
      });
    const { data: searchFieldsData } = useQuery(GET_SEARCH_FIELDS, { variables: { manualCode: manualCode} })

    const saveSearchParamState = (pn, e) => {
        const searched = searchFieldsData.manuals.getManualSearchParam.find(p => p.paramName === pn);
        const index = params.findIndex(v => v.paramName === searched.paramName);
        const currentVals = [...params];

        if (index === -1) currentVals.push({paramName: pn, paramValue: e.target.value});
        else currentVals[index].paramValue = e.target.value;
        setParams(currentVals);
        setNoResult(true);
    }
    const handlePinClick = (isPinning, fileName, title, subTitle) => {
        //debugger
        const currentpins = [...pinsinfo.data.pins];
        if (isPinning) currentpins.unshift({ manualCode: manualCode, fileName: fileName, title: title, subTitle: subTitle});
        else {
            const index = currentpins.findIndex(p => p.fileName === fileName);
            if (index !== -1) currentpins.splice(index, 1); 
        }
        setPins(currentpins);
        updateManualPin({ variables: {
                employeeId: userId || 'V726082', 
                manualCode: manualCode, 
                fileName: fileName, 
                isActive: isPinning
            } 
        });
    }

    const handleKeypress=(params, manualCode, isPreview, e) => {
        console.log("key pressed", e.key);
        if (e.key === 'Enter') {
            getConfigDrawings({variables: { searchParams: params, manualCode: manualCode, isPreview : isPreview }});
            setNoResult(false);
          }
    }

    return (
        <div>
            {
                isHtmlManual?
                    <div className={styles.helpbar}>
                        <div className={styles.helpbox}>
                            <div className={styles.itemI}>i</div>
                            <div>Use "CTRL+F" to search for text within the current page</div>
                        </div>
                    </div>
                    :null
            }
            <div className={styles.navigation}>
                <div className={styles["left-arrow"]} onClick={()=>{props.close();props.switchtocontent();}}></div>
                <div className={styles.linktitle}>Return to Table of Contents</div>
            </div>
        <div className={styles.search} style={{maxHeight: props.innerHeight}}>
            <div className={styles['search-types']}>
                
            {
                searchFieldsData && searchFieldsData.manuals.getManualSearchParam ? 
                    searchFieldsData.manuals.getManualSearchParam.map((d, i) => (
                        <div key ={i} className={styles.type}>
                            <div className={styles.label}>{d.paramName}</div>
                            <div><input type='text' placeholder='Search' onChange={(e) => {saveSearchParamState(d.paramName, e)} } onKeyPress={(e) =>{handleKeypress(params, manualCode, isPreview, e)}}/></div>
                        </div>
                    )
                ) : <div className={styles.type}>Currently there are no fit search criteria for the manual.</div>
            }
            {
                searchFieldsData && searchFieldsData.manuals.getManualSearchParam ? 
                <div className={styles.item}>
                    <input 
                        type='button' 
                        value='Search' 
                        onClick={() => {getConfigDrawings({variables: { searchParams: params, manualCode: manualCode, isPreview : isPreview }}); setNoResult(false);}} disabled={!params || params.length === 0} /></div>
                : null
            }
            </div>
            <div className={styles.results}>
                <div>                    
                    <div className={styles.label}>{noResult && !(data && data.manuals.getManualSearch.length > 0) ? "" : "Results"}</div>
                </div>
                <div className={styles['searched-docs']}>
                {
                    loading ? 
                    <div className={styles['spinner-section']}>
                        <div className={styles.spinner}></div>
                    </div>
                    :
                    data && data.manuals.getManualSearch.length > 0 ? 
                        data.manuals.getManualSearch.map((d, i) => {
                            return (
                                <div key={i} className={styles.doc}>
                                    <div className={styles['title-section']} onClick={() => {setViewerLoading(true); setSelectedDocument({fileName: d.fileName}); close();}}>
                                        <div>{d.title}</div>
                                        <div className={styles.subTitle}>{d.subTitle}</div>
                                    </div>
                                    <div className={styles.actions}>
                                        <div>
                                            {
                                                pinsinfo && pinsinfo.data && pinsinfo.data.pins.findIndex(p => p.fileName === d.fileName) !== -1 ? 
                                                <Icon onClick={() => handlePinClick(false, d.fileName, d.title, d.subTitle)} className={styles['pinned-item']} variant='pinned' width='24px' height='23px' /> : 
                                                <Icon onClick={() => handlePinClick(true, d.fileName, d.title, d.subTitle)} variant='unpinned' width='24px' height='23px' />
                                            }
                                        </div>
                                        {
                                            isHtmlManual ? 
                                            <div onClick={() => window.open(`/udocs/htmlviewer?manualCode=${manualCode}&fileName=${d.fileName}&isPreview=${isPreview}&title=${d.title}`, '_blank')}><Icon variant='popout' /></div>
                                            :
                                            <div onClick={() => window.open(`/udocs/pdfviewer?manualCode=${manualCode}&fileName=${d.fileName}&isPreview=${isPreview}&title=${d.title}`, '_blank')}><Icon variant='popout' /></div>
                                        }
                                    </div>
                                </div>
                            )
                        })
                    :
                    noResult ? null :
                        <div className={styles.doc}>Currently there are no documents that fit search criteria.</div>
                }
            </div>
            </div>
        </div>
        </div>
    )
}

export default Search;