import React, { createContext, useRef, useState, useEffect } from 'react';

export const ModalContext = createContext();

const ModalContextProvider = ({ children }) => {
    const modalRef = useRef();
    const [modalContext, setModalContext] = useState();

    useEffect(() => {
        setModalContext(modalRef.current)
    }, []);

    return (
        <>
            <ModalContext.Provider value={modalContext}>
                {children} 
            </ModalContext.Provider>
            <div ref={modalRef} />
        </>
    )
}

export default ModalContextProvider;