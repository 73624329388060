import React,  { useState } from 'react';
import SortMenu from '../SortMenu/SortMenu';

import styles from './searchfilters.module.scss';

const SearchFilters = (props) => {
    const [sortOption, setSortOption] = useState('Title A-Z')
    const [showSort, setShowSort] = useState(false);

    const setSort = (opt) => {
        switch (opt) {
            case 'TA': setSortOption('Title A-Z'); break;
            case 'TZ': setSortOption('Title Z-A'); break;
            case 'RD': setSortOption('Revision Date'); break;
            default: break;
        }
        sortItems(opt);
    }

    const { useButton, filterItems, sortItems } = props;
    return (
        <>
            <div className={styles.subtitle}>Title Search</div>
            <div className={styles.filters}>
                <div>
                    <input type='text' placeholder='Search' onChange={e => filterItems(e)} />
                </div>
                {
                    useButton ?
                    <div><input type='button' className='primary-btn' value='search' onClick={() => alert('test')} /></div>
                    : null
                }
                <div className={styles.sort}>
                    <div className={styles.sorticon} onClick={() => setShowSort(!showSort)}><span></span></div>
                    <div>{`Sort: ${sortOption}`}</div>
                </div>
                <SortMenu show={showSort} setShow={setShowSort} setSortOption={setSort} />
            </div>
        </>
    )
}

export default SearchFilters;