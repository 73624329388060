import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import cx from 'classnames';
import styles from './doctable.module.scss';
import Icon from '../Icon/Icon';
import PinItems from '../DocTile/PinItems/PinItems';
import { GET_RECENT_MANUALS, GET_FAVORITE_MANUALS } from '../../operations/queries/manuals';
import { HomeContext } from '../../containers/Home/HomeContext';
import { ViewerContext } from '../../containers/Viewer/ViewerContext';
import { mutations, UPDATE_EMP_FAV } from '../../operations/mutations';


const DocTable = (props) => {
    const { isFav, empId} = props;
    const recentManuals = useQuery(GET_RECENT_MANUALS);
    const favoriteManuals = useQuery(GET_FAVORITE_MANUALS);
    const { selectedPinManualCode, setSelectedPinManualCode } = useContext(HomeContext);
    const { setSelectedDocument, setHtml, setSearchEnabled, setViewerLoading, setSearchFound } = useContext(ViewerContext);
    const [updateFavorite, { data }] = useMutation(UPDATE_EMP_FAV);
    const { selectManual, setRecent, setFavorites } = mutations;
    const history = useHistory();
    const location = useLocation();
    const [isFavorite, setIsFavorite] = useState(false);
    const [expandAlert, setExpandAlert]=useState(true);

    const handleViewManualClick = (info, title, category, isHtml) => {
        const newrecent = { categoryName: category, manualName: title, manualCode: info.manualCode, hasAlerts: info.hasAlerts, revDate: info.revDate, revNumber: info.revision ? info.revision : '',
                            uRL: info.uRL, typeInfo: info.fleetType || info.vendorName || info.engineType || null,
                            fleetType: info.fleetType || null, vendorName: info.vendorName || null, engineType: info.engineType || null };
        const currentrecent = [...recentManuals.data.recent];
        if (recentManuals.data.recent.findIndex(r => r.manualCode === info.manualCode) === -1) {
            if (recentManuals.data.recent.length > 9) currentrecent.pop();
            currentrecent.unshift(newrecent);
        }
        setSelectedDocument(null);
        setRecent(currentrecent);
        selectManual(newrecent);
        setHtml(isHtml);
        setSearchEnabled(info.isSearchEnabled);
        if(info.uRL)
            window.open(info.uRL, '_blank')
        else
            history.push('/viewer');
    }
    const handleFavoriteClick = (row, isfav) => {
        const currentfavs = [...favoriteManuals.data.favorites];
        const userId = empId;
        if (userId) {
            if (isfav) currentfavs.unshift({ manualCode: row.manualCode });
            else {
                const index = currentfavs.findIndex(f => f.manualCode === row.manualCode);
                if (index !== -1) currentfavs.splice(index, 1);
            }
            updateFavorite({
                variables: {
                    "employeeId": userId,
                    "manualCode": row.manualCode,
                    "isActive": isfav
                }
            });

            setFavorites(currentfavs);
            if (location.pathname !== '/favorites') setIsFavorite(isfav);
        }
    }
    
    const handleSearchClick =(manualCode, fileName, count) =>{
        const foundcategory = props.employeeManual.find(m => m.fleetManuals.findIndex(fm => fm.manualCode === manualCode) !== -1 || 
                                                                            m.vendorManuals.findIndex(vm => vm.manualCode === manualCode) !== -1 || 
                                                                            m.engineManuals.findIndex(em => em.manualCode === manualCode) !== -1);
        const foundmanual = foundcategory && foundcategory.categoryName === 'Fleet' ? foundcategory.fleetManuals.find(fm => fm.manualCode === manualCode) : 
                                                                            foundcategory && foundcategory.categoryName === 'Vendor' ? foundcategory.vendorManuals.find(vm => vm.manualCode === manualCode) : 
                                                                            foundcategory && foundcategory.categoryName === 'Engine' ? foundcategory.engineManuals.find(em => em.manualCode === manualCode) : null;
        
        if (foundmanual) {
            const newrecent = {
                categoryName: foundcategory.categoryName,
                manualName: foundcategory.manualName,
                manualCode: manualCode,
                hasAlerts: foundmanual.hasAlerts,
                revDate: foundmanual.revDate, 
                revNumber: foundmanual.revision,
                uRL: null,
                typeInfo: foundmanual.fleetType || foundmanual.vendorName || foundmanual.engineType || null,
                fleetType: foundmanual.fleetType || null,
                vendorName: foundmanual.vendorName || null, 
                engineType: foundmanual.engineType || null
                };
            const newfile = {
                fileName: fileName,
                hasChildren: false,
                hasDocument: true,
                isHtml: null,
                subTitle: null,
                title: null
            }

                setSelectedDocument(newfile);
                selectManual(newrecent);
                setHtml(foundcategory.isHtml);
                setSearchEnabled(foundcategory.isSearchEnabled);
                setViewerLoading(true); 
                setSearchFound(count);
                history.push({ pathname: './viewer', state: 'search' });    
        }
    }

    const tableHeader = (
        <thead className={styles.tablehead}>
            <tr>
                <th>Title</th>
                <th>REV # - Date</th>
                <th></th>
            </tr>
        </thead>
    );
    const tableData = () => {
        return (
            props.rowData.map((row, index) => {
                return (
                    <tr >
                        <td><div>{row.manualName}</div> <div className={styles.subScript}>{row.typeInfo}</div></td>
                        <td>{row.revision ? row.revision + ' - ' : ''}{moment(row.revDate).format('MM/DD/YYYY')}</td>
                        <td>
                            <div className={styles.actions}>
                                <Icon variant={props.isFav.findIndex(fa => fa.manualCode === row.manualCode) !== -1 ? 'favorite' : 'unfavorite'} width="26px" height="25px" clickable='true' onClick={() => handleFavoriteClick(row, !(isFav.findIndex(fa => fa.manualCode === row.manualCode) !== -1))} />
                                {
                                    row.hasPins ? 
                                    <div onClick={() => setSelectedPinManualCode(row.manualCode)}><Icon variant='pinned' className={styles['pinned-item']} clickable='true' /></div>
                                    :
                                    <div><Icon variant='unpinned' className={styles['pinned-item']} clickable='false' /></div>
                                }
                     
                            {
                                selectedPinManualCode === row.manualCode ?
                                    <PinItems title={row.manualName} info={row} category={row.categoryName} setShow={setSelectedPinManualCode} manualCode={row.manualCode} empId={props.empId} isHtmlManual={row.isHtml} isSearchEnable={row.isSearchEnabled} />
                                    : null
                            }
                                <div className={styles.manual} onClick={() => handleViewManualClick(row, row.manualName, row.categoryName, row.isHtml)}><div>View Manual</div><div className={styles.arrow}></div></div>
                            </div>
                        </td>
                    </tr>
                )
            })
        )
    }
    const tableTitleSearch = (                
        <div className={styles.clapsetitle}>{props.rowData.manualName}
            <div className={cx({[styles['up-arrow']]:expandAlert}, {[styles['down-arrow']]:!expandAlert}, styles['alertarrow'])} onClick={() => {setExpandAlert(!expandAlert);}}></div>
        </div>                       
    );
    const tableHeaderSearch = (
    expandAlert && props.rowData.advanceSearchDocuments ?
    <thead className={styles.tablehead}>
        <tr>
            <th>Document</th>
            <th>Results found</th>
            <th></th>
        </tr>
    </thead>
    :null
    );
    const tableDataSearch = () => {
    return (
        expandAlert && props.rowData.advanceSearchDocuments ?
        props.rowData.advanceSearchDocuments.map((row, index) => {
            return (
                <tr >
                    <td width= "73%">
                        <div className={styles.documenttitle}>
                            <div className={styles.subScript}>{row.title}</div> {/*<div>{row.subsection}</div>*/}
                        </div>
                    </td>
                    <td><div className={styles.aligncenter}>{row.count}</div></td>
                    <td>
                        <div className={styles.actions}>
                            <div className={styles.manual} onClick={() => handleSearchClick(row.manualCode, row.fileName, row.count)}><div>View Document</div><div className={styles.arrow}></div></div>
                        </div>
                    </td>
                </tr>
            )
        })
        :null
        
    )
    }
    return (
        <>
        {props.tableFrom==="Search" ? tableTitleSearch : null}
        <table className={cx(styles.tableContainer,{[styles.tableContainerWithMargin]: props.tableFrom!=="Search"})}>            
            {props.tableFrom==="Search" ? tableHeaderSearch : tableHeader}
            {
                props.tableFrom!=="Search"  || expandAlert ?
                    <tbody className={cx(styles.tablebody,{[styles.tablebodyevenwidth]: props.tableFrom!=="Search"})}>
                        {props.tableFrom==="Search" ? tableDataSearch(props) : tableData(props)}
                    </tbody>
                : null
            }
        </table>
        </>
    )
};

export default DocTable;