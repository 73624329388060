import React, { useState } from 'react';
import cx from 'classnames';
import { useHistory, useLocation, Link } from 'react-router-dom';

import styles from './navigation.module.scss';
import Icon from '../Icon/Icon';
import { Modal } from '../Modal/Modal';
import HelpLegend from '../HelpLegend/HelpLegend';

const Navigation = (props) => {
    const history = useHistory(); const location = useLocation();
    const [openHelp, setOpenHelp] = useState(false);
    const { adminlogin, reviewCount } = props;

    return (
        <nav className={styles['left-nav']}>
            <div>
                <input id='menu-toggle' className={styles['menu-toggle']} type='checkbox' />
                <label className={styles['menu-btn']} htmlFor='menu-toggle'>
                    <span></span>
                </label>
                <ul className={styles['menu']}> 
                    <li>
                        <div className={cx(styles['menu-item'], {[styles['menu-item-selected']]: location.pathname === '/'}, {[styles['menu-item-unselected']]: location.pathname !== '/'})} onClick={() => {history.push('/');}}>
                            <div><Icon variant='home' width='24px' clickable='false'/></div>
                            <div className={styles['item-label']}>UDocs Home</div>
                        </div>
                    </li>
                    <li>
                        <div className={cx(styles['menu-item'], {[styles['menu-item-selected']]: location.pathname === '/search'}, {[styles['menu-item-unselected']]: location.pathname !== '/search'})} onClick={() => {history.push('/search');}}>
                            <div><Icon variant='search' width='24px' clickable='false'/></div>
                            <div className={styles['item-label']}>Advanced Search</div>
                        </div>
                    </li>
                    <li>
                        <div className={cx(styles['menu-item'], {[styles['menu-item-selected']]: location.pathname === '/recent'}, {[styles['menu-item-unselected']]: location.pathname !== '/recent'})} onClick={() => {history.push('/recent');}}>
                            <div><Icon variant='recent' width='24px' clickable='false' primarycolor={location.pathname === '/recent' ? '#6244BB' : '#D8D8D8'} /></div>
                            <div className={styles['item-label']}>Recently Viewed</div>
                        </div>
                    </li>
                    <li>
                        <div className={cx(styles['menu-item'], {[styles['menu-item-selected']]: location.pathname === '/favorites'}, {[styles['menu-item-unselected']]: location.pathname !== '/favorites'})} onClick={() => {history.push('/favorites');}}>
                            <div><Icon variant='favorite' width='24px' clickable='false'/></div>
                            <div className={styles['item-label']}>Favorites</div>
                        </div>
                    </li>
                    {adminlogin ? <li>
                        <div className={cx(styles['menu-item'], {[styles['menu-item-selected']]: location.pathname === '/AdminPanel'}, {[styles['menu-item-unselected']]: location.pathname !== '/AdminPanel'})} onClick={() => {history.push('/AdminPanel');}}>
                            <div><Icon variant='adminpanel' width='24px' clickable='false'/></div>
                            {console.log('reviewcount: '+ reviewCount)}
                            {reviewCount!=0? <div><span className={styles['badge-warning']}>{reviewCount}</span></div> : null}
                            <div className={styles['item-label']}>Review Manuals</div>
                        </div>
                    </li> : null}
                    <li>
                        <div className={`${styles['menu-item']} ${styles['menu-item-unselected']} ${styles['information-section']} ${styles['no-hover']}`}>
                            <div className={`${styles.information} ${styles['item-label']}`}>i</div>
                            <div className={styles['item-label']}>Additional Resources</div>
                        </div>
                        <div className={styles.divider}></div>
                    </li>
                    <li>
                        <div className={styles['link-item']}>
                            <div className={styles['item-label']}><Link to={{pathname: '/pdfviewer', search:'?static=safety'}} target='_blank' >SLIDE safety flyer</Link></div>
                        </div>
                    </li>
                    <li>
                        <div className={styles['link-item']}>
                            <div className={styles['item-label']}><Link to={{pathname: '/pdfviewer', search:'?manualCode=123025&fileName=46-0035.pdf&isPreview=false&title=46-0035 - Escape Slide Render Safe Checklist'}} target='_blank' >SLIDE render safe checklist</Link></div>
                        </div>
                    </li>
                    <li>
                        <div className={styles['link-item']}>
                            <div className={styles['item-label']}><Link to={{pathname: '/pdfviewer', search:'?manualCode=123025&fileName=41-1070.pdf&isPreview=false&title=41-1070 - Tow Safety Checklist'}} target='_blank' >Tow safety checklist</Link></div>
                        </div>
                    </li>
                    <li>
                        <div className={styles['link-item']}>
                            <div className={styles['item-label']}><a href='https://united.service-now.com/hrportal/?id=sc_cat_item&amp;sys_id=5fc32fdb1bca370072170e53cd4bcb3a' rel='noreferrer' target='_blank'>Give Feedback</a></div>
                        </div>
                    </li>
                    <li>
                        <div className={styles['link-item']}>
                            <div onClick={() => setOpenHelp(true)} className={styles['item-label']}><span className={styles.help}>?</span><span className={styles['psudo-link']}>Help</span></div>
                        </div>
                    </li>
                </ul>

                {
                    openHelp && 
                    <Modal closeModal={() => setOpenHelp(false)} title='UDocs Help'>
                        <HelpLegend />
                    </Modal>
                }
            </div>
        </nav>
    )
}

export default Navigation;