import React, {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";

const ShadowDOM = ({ children }) => {
    const ref = useRef()
    const [shadowRoot, setShadowRoot] = useState()

    useEffect(() => {
        if (ref.current) {
            setShadowRoot(
                ref.current.attachShadow({ mode: 'open' })
            )
        }
    }, [ref])

    return (
        <div ref={ref} id='udocs-html-content-shadowroot'>
            {shadowRoot && createPortal(children, shadowRoot)}
        </div>
    )
}

export default ShadowDOM
