import { jwtDecode } from "jwt-decode";
import config from "../config/development";
import axios from "axios";

let isRefreshing = false;
let tokenQueue = [];
let signOutTimeout = null;

export const setTokenExpiry = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    const expiryTime = decodedToken.exp * 1000; // Convert to milliseconds
    localStorage.setItem("tokenExpiry", expiryTime - config.accessTokenExpiryOffsetMinutes * 60 * 1000);
  } catch (error) {
    console.error("Failed to decode access token", error);
  }
};

const isTokenExpired = () => {
  const expiry = parseInt(localStorage.getItem("tokenExpiry"), 10);
  const currentTime = Date.now();
  return expiry < currentTime;
};

const setNewAccessToken = async () => {
  //console.log('refresh token start');
  const refreshToken = localStorage.getItem("refreshToken");
  const code_verifier = localStorage.getItem("code_verifier");
  const params = new URLSearchParams();
  params.append("grant_type", config.grantTypeRefreshToken);
  params.append("redirect_uri", config.redirectUri);
  params.append("refresh_token", refreshToken);
  params.append("code_verifier", code_verifier ? code_verifier : "");

  try {
    const res = await axios.post(config.tokenEndpoint, params, {
      headers: {
        "X-OAUTH-IDENTITY-DOMAIN-NAME": `${config.domain}`,
        "Authorization": `${config.basicCode}`,
      }
    });
    //console.log('refresh token success');
    localStorage.setItem("accessToken", res.data.access_token);
    localStorage.setItem("idToken", res.data.id_token);
    localStorage.setItem("refreshToken", res.data.refresh_token);
    localStorage.setItem("tokenStartTime", Date.now() + config.refreshTokenExpiresInHours * 60 * 60 * 1000);
    setTokenExpiry(res.data.access_token);
    setRefreshTokenExpiry();
    return res.data.access_token;
  } catch (err) {
    console.log('refresh token error: ' + err);
    throw err;
  }
};

export const getAccessToken = async () => {

  if (!isTokenExpired()) {
    return localStorage.getItem("accessToken");
  }

  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      tokenQueue.push({ resolve, reject });
    });
  }

  isRefreshing = true;

  try {
    const newToken = await setNewAccessToken();
    isRefreshing = false;
    tokenQueue.forEach(({ resolve }) => resolve(newToken));
    tokenQueue = [];
    return newToken;
  } catch (error) {
    isRefreshing = false;
    tokenQueue.forEach(({ reject }) => reject(error));
    tokenQueue = [];
    throw error;
  }
};

export const setRefreshTokenExpiry = () => {
  try {
    const tokenStartTime = parseInt(localStorage.getItem("tokenStartTime"), 10);
    const expiryTime =  tokenStartTime - Date.now();
    //console.log("expiry time  in " + expiryTime);
    if(expiryTime < 0)
      signOut()
    else{
      setSignOutTimeout(expiryTime);
    }
  } catch (error) {
    console.error("Failed to set refresh token expiry", error);
  }
};

const setSignOutTimeout = (timeoutDuration) => {
  localStorage.setItem("timeout", timeoutDuration);
  if (signOutTimeout) {
    clearTimeout(signOutTimeout);
  }
  signOutTimeout = setTimeout(() => {
    signOut();
  }, timeoutDuration);
};

const signOut = () =>{
  //console.log("SignOut start")

    const idTokenValue = localStorage.getItem("idToken")

    const params = {
      token: idTokenValue,
      redirect_uri: config.redirectUri
    };

    // Call LogOut URL
    axios.get(`${config.logoutURL}${idTokenValue}`, { data: params })
      .then(res => {
        //console.log("Logout response:- ", res)

        // Clear Storage
        localStorage.clear()
        localStorage.setItem("loggedOut", "true")

        window.location.href = config.redirectUri;
      })
      .catch(err => {
        console.log("error", err)
      })
}
