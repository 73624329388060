import React, { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import ViewSDKClient from '../../pdfclient/ViewSDKClient';
import { ViewerContext } from '../../containers/Viewer/ViewerContext';
import { GET_DOCUMENT } from '../../operations/queries/documents';
import styles from './lightviewer.module.scss';
import Icons from '../../components/Icon/Icon';

//pdf plugin
import { Worker } from '@react-pdf-viewer/core';
import { Icon, MinimalButton, Position, Tooltip, Viewer, SpecialZoomLevel, RenderPage, RenderPageProps } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import '@react-pdf-viewer/bookmark/lib/styles/index.css';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import { NextIcon, PreviousIcon, RenderSearchProps, searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import DsViewer from '../../components/PdfProViewer/DsViewer.js';
import config from '../../config/development.js'

//end pdf plugin

const LightViewer = (props) => {
    const params = new URLSearchParams(useLocation().search);
    const manualCode = params.get('manualCode'); const fileName = params.get('fileName');const isPreview = params.get('isPreview');
    const title=params.get("title");
    const [pdfWorkerLoading, setPdfWorkerLoading]=useState(true);

   // const pdfcontent = useQuery(GET_DOCUMENT, {variables: { manualCode: manualCode, fileName: fileName }});  

       //pdf plugin 
       const [urlPath, setUrlPath] =useState("");
       const bookmarkPluginInstance = bookmarkPlugin();
       const { Bookmarks } = bookmarkPluginInstance;
       const defaultLayoutPluginInstance = defaultLayoutPlugin();
     
       const thumbnailPluginInstance = thumbnailPlugin();
       const { Thumbnails } = thumbnailPluginInstance;
       const searchPluginInstance = searchPlugin();
       const { Search } = searchPluginInstance;
       const fullScreenPluginInstance = fullScreenPlugin();
       const { EnterFullScreenButton } = fullScreenPluginInstance;
       //end pdf plugin
   
       //pdf plugin
       const [readyToSearch, setReadyToSearch] = useState(false);
       //end pdf plugin

    let staticdoc = null;
    staticdoc =  useLocation().search === '?static=safety' ? `${config.REACT_APP_ASSET_BASE}/slide-flyer.pdf` :  staticdoc;
    //staticdoc = useLocation().search === '?static=checklist' ? `${config.REACT_APP_ASSET_BASE}/46-0035-Static-PDF.pdf` : staticdoc;
    staticdoc = useLocation().search === '?static=tow' ? `${config.REACT_APP_ASSET_BASE}/41-1070-Tow safety checklist.pdf` : staticdoc;
    
    useEffect(() => {
        if (staticdoc) {
           /* const sdkClient = new ViewSDKClient();
            const userAgent = window.navigator.userAgent;
            // console.log('User Agent', userAgent);
            let showPrint = true;
            if (userAgent.indexOf('Firefox') > -1) showPrint = false;
            
            sdkClient.ready().then(() => {
                sdkClient.previewFile('pdf-light-viewer', {
                    // If true, the left-hand pane in file preview displays. The pane allows user to toggle thumbnails on and off. 
                    showLeftHandPanel: true,
                    showPrintPDF: showPrint
                }, staticdoc, staticdoc.includes('flyer') ? 'Safety Flyer' : 'Safe Checklist');
            });*/
            setUrlPath(staticdoc);
        }
        else
        {
          /*  const sdkClient = new ViewSDKClient();
            sdkClient.ready().then(() => {   
                console.log('User Agent preview');
                if(isPreview==="true")   {
                     const urlPath= config.REACT_APP_DOC_STREAM + manualCode+"/"+fileName + "/" + false;
                     sdkClient.previewFile('pdf-light-viewer',"", urlPath, fileName);  
                }    else{
                    const urlPath= config.REACT_APP_DOC_STREAM + manualCode+"/"+fileName;
                     sdkClient.previewFile('pdf-light-viewer',"", urlPath, fileName);  
                }      
                                                                     
            }); */
            //pdf plugin
            if(isPreview==="true")   {
                // fetch(config.REACT_APP_DOC_STREAM + manualCode+"/"+fileName + "/" + false)
                fetch(config.REACT_APP_DOC_PRESIGNEDURL + manualCode+"/"+fileName+ "/" + false)
                .then(res => { return res.json()})
                        .then((res) => {
                            console.log("=====", res.s3PreSignedUrl);
                            fetch(res.s3PreSignedUrl)
                .then(res => res.blob()) 
                .then(blob => {
                var newBlob = new Blob([blob], {type: "application/pdf"})
                setUrlPath(URL.createObjectURL(newBlob));
                });
            })
            }
            else{
                //fetch(config.REACT_APP_DOC_STREAM + manualCode+"/"+fileName)
                fetch(config.REACT_APP_DOC_PRESIGNEDURL + manualCode+"/"+fileName)
                .then(res => { return res.json()})
                        .then((res) => {
                            console.log("=====", res.s3PreSignedUrl);
                            fetch(res.s3PreSignedUrl)
                .then(res => res.blob()) 
                .then(blob => {
                var newBlob = new Blob([blob], {type: "application/pdf"})
                setUrlPath(URL.createObjectURL(newBlob));
                });
            })
            }
          //end pdf plugin
        }
    }, [staticdoc])


    useEffect(() => {
        if(title )
        {
          document.title=title;
        }
      }, [])

/*      useEffect(() => {
        //pdf plugin
        fetch(config.REACT_APP_DOC_STREAM + manualCode+"/"+fileName)
        .then(res => res.blob()) 
        .then(blob => {
          var newBlob = new Blob([blob], {type: "application/pdf"})
           console.log(newBlob);
          setUrlPath(URL.createObjectURL(newBlob));
        });
        
      }, [])*/
/*    useEffect(() => {            
            const sdkClient = new ViewSDKClient();
            sdkClient.ready().then(() => {   
              debugger;
                if(isPreview==="true")   {
                     const urlPath= config.REACT_APP_DOC_STREAM + manualCode+"/"+fileName + "/" + false;
                     sdkClient.previewFile('pdf-light-viewer',"", urlPath, fileName);  
                }    else{
                    const urlPath= config.REACT_APP_DOC_STREAM + manualCode+"/"+fileName;
                     sdkClient.previewFile('pdf-light-viewer',"", urlPath, fileName);  
                }      
                                                                     
            }); 
    })*/
    const handleDocumentLoad = (e) => {
        const { activateTab } = defaultLayoutPluginInstance;
        setPdfWorkerLoading(false);
        // Activate the bookmark tab whose index is `1`
        activateTab(1);
    };

    const handlePrintClick=()=>{        
            var pwa = window.open(urlPath, "pdfwindowprint");
            
            console.log("pwa",pwa);
            setTimeout(() => {
                pwa.print();
                }, 50);
           // pwa.close();
        //window.open('_blank', 'noopener noreferrer')
    };
 
  
    return (

        <div>
        <div className={styles.infobar}>
        {manualCode==123025 ? "" :
        <div className={styles.titlebanner}>
                <div className={styles.bannericon} onClick={() => handlePrintClick()}><Icons variant='print' width='30px' height='34px' clickable={true}/></div>
            </div>}
        </div>
        <div   style={{
            //border: '1px solid rgba(0, 0, 0, 0.3)',
            height: '920px',
            width: '100%',
        }} >
        {
            pdfWorkerLoading?
            <div className={styles['spinner-section']}>
                <div className={styles.spinner}></div>
            </div>
            :null
        }
            {manualCode==123025 ? urlPath && <DsViewer fileUrl={urlPath} viewerLoaded={handleDocumentLoad}/> :
           urlPath && <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js">                      
                <div
                    style={{
                        border: '1px solid rgba(0, 0, 0, 0.3)',
                        display: 'flex',
                        height: '100%',
                    }}
                >
                    
                    <div  style={{ flex: 1 }}>
                        <Viewer fileUrl={urlPath} plugins={[defaultLayoutPluginInstance,
                             bookmarkPluginInstance,
                              thumbnailPluginInstance,
                               searchPluginInstance]} onDocumentLoad={handleDocumentLoad} defaultScale={SpecialZoomLevel.PageFit}/>
                    </div>
                </div>


                </Worker>}

        </div>
    </div>  

    )
}

export default LightViewer;