const CONFIG = {
   ENV: 'development',
   REACT_APP_API_URL_BASE : 'https://udocsservice.dev.efo.aws.ual.com/contentstore',
   REACT_APP_GRAPHQL_URL : '/graphql',
   REACT_APP_PDF_API_LICENSE : "United Airlines Inc.,*.ual.com,313687196775984#B03T3NWc7BVNNl6UKJjVoRXdqNUTOdFRmJmb4E7Y6ZUMkhjaEJXW4Y5StRTTHJVNq96SvBjThRFOr3SSptyL5gFO0p5RshURzcje93iVxV4apNzNIF7NxoXS9I5UShUZ6tWTNlmN9F6R4BTchdkQC9Ee6g4KhhGZ5YGS73iaVRUeBdHdXNUUJdETl54VP5WNop4NSl4NSF5QGFjWoF5MBZ7QTVTU6FEV4QGZ9MlT5RUUlxWUEV4c5JUelBFdUN4QGBFZzM4QIxWQ7N6RzhVawdEcvpESnpkVhlWUUlXZ9cXQGJlVFpkI0IyUiwiI7cjRDhTMwMjI0ICSiwiMzIDO8UDM5ITM0IicfJye=#Qf35VfiokR6gjI0IyQiwiIvJHUgcjVgIXZ7VWaWZGZQN4RiojIOJyebpjIkJHUiwiIyIzM5cDMgkjM7ADNyAjMiojI4J7QiwiIt36YuwWY59iKiojIz5GRiwiIuMmbJBycl9WasJXaBBCZlRXauVlI0ISYONkIsICN8kTN7cjN9EzN8YzMxMjI0ICZJJye0ICRiwiI34zd4czQHZXOFl4T9VHVGFlQKJVW7d4LVJHavgDZwgjM9h5TElTNuhlTmFVSnlmW4RmSrAzYxN4VFJmZLBlRLxESw36V7EzN6FTexV7dLFWNFF4MOllNrZjRHNEV7olTzNDbvhDO9d4cR9GcwlGT6JVU4E",
   REACT_APP_ASSET_BASE : 'https://udocs.dev.efo.aws.ual.com',
   REACT_APP_PDF_LICENSE : 'b7c6f71ea1c84fc69a27009e0145d2c9',
   REACT_APP_DOC_STREAM : 'https://udocsservice.dev.efo.aws.ual.com/download/File/GetStreamTask/',
   REACT_APP_DOC_PRESIGNEDURL : 'https://udocsservice.dev.efo.aws.ual.com/download/File/GetPreSignedURL/',
   REACT_APP_LOGOUT_URL : 'https://signon-oqa.ual.com/oauth2/rest/userlogout?id_token_hint=',

   //RUM Configuration
   APPLICATION_ID : '6b975c36-6848-46e3-95ab-bddf8c90c0c7',
   CLIENT_TOKEN : 'pub6bca999e7585edd6a3bb26d2c209b75c',
   SERVICE_NAME : 'udocs-cloud',

   // SSO
   client_id: 'UDOCSCLOUD_ODEV_CID',
   authorizationEndpoint: 'https://signon-oqa.ual.com/oauth2/rest/authorize',
   tokenEndpoint: 'https://signon-oqa.ual.com/oauth2/rest/token',
   redirectUri: 'https://udocs.dev.efo.aws.ual.com',
   basicCode: 'Basic VURPQ1NDTE9VRF9PREVWX0NJRDphM2MwNTg1NzA2ZDE0OGU1OGRiN2ZmZGE5ODhiMjNkMA==',
   grantType: 'AUTHORIZATION_CODE',
   grantTypeRefreshToken: 'REFRESH_TOKEN',
   responseType: 'code',
   scope: 'openid profile UDOCSCLOUD_ODEV_RS01.ALL',
   domain: 'UDOCSCLOUD_ODEV',
   codeChallengeMethod: 'S256',
   logoutURL: 'https://signon-oqa.ual.com/oauth2/rest/userlogout?id_token_hint=',
   userInfo: 'https://signon-oqa.ual.com/oauth2/rest/userinfo',
   accessTokenExpiryOffsetMinutes: 5, // acess token will be valid for 4hrs - 5mins i.e 3hr 55 mins 
   refreshTokenExpiresInHours  : 10 // refresh token expires  10 hrs from last access token time fetched and sign out automatically
}

export default CONFIG;
