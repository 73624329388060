import React, { createContext, useState } from 'react';

export const ViewerContext = createContext();

const ViewerContextProvider = (props) => {
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [isViewerLoading, setViewerLoading] = useState(false);
    const [isHtml, setHtml] = useState(false);
    const [currentSelectedSections, setCurrentSelectedSections] = useState([]);
    const [currentSelectedSubSections, setCurrentSelectedSubSections] = useState([]);
    const [scrollPosition, setScrollPosition]=useState();
    const [previousManualCode, setPreviousManualCode]=useState();
    const [isSearchEnabled, setSearchEnabled] = useState(true);
    const [currentManualCode, setCurrentManualCode]=useState();
    const [currentPreviewStatus, setCurrentPreviewStatus]=useState();
    const [tableContent, setTableContent]=useState();
    const [selectedTrail, setSelectedTrail] = useState([]);
    const [searchKeyword, setSearchKeyword]=useState();
    const [searchFound, setSearchFound]=useState();
    const [adSearchResult, setADSearchResult]=useState([]);
    const [fromViewer, setFromViewer]=useState(false);
    const [searchManuals, setSearchManuals] = useState([]);
    const [searchManualsDisplay, setSearchManualsDisplay] = useState("");
    const [pdfWorkerLoading, setPdfWorkerLoading]=useState(false);

    return (
        <ViewerContext.Provider value={{selectedDocument, setSelectedDocument, isViewerLoading, setViewerLoading,isHtml, setHtml,currentSelectedSections, setCurrentSelectedSections,currentSelectedSubSections, setCurrentSelectedSubSections,scrollPosition, setScrollPosition, previousManualCode, setPreviousManualCode, isSearchEnabled, setSearchEnabled, currentManualCode, setCurrentManualCode, currentPreviewStatus, setCurrentPreviewStatus, tableContent, setTableContent, selectedTrail, setSelectedTrail, searchKeyword, setSearchKeyword,searchFound, setSearchFound,adSearchResult, setADSearchResult, fromViewer, setFromViewer, searchManuals, setSearchManuals, searchManualsDisplay, setSearchManualsDisplay, pdfWorkerLoading, setPdfWorkerLoading }}>
            {props.children}
        </ViewerContext.Provider>
    )
}
export default ViewerContextProvider;