import React from 'react';
import ReactDOM from 'react-dom';
import { isIE } from 'react-device-detect'
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { getEnvironment } from './utils/envUtils';
import UserContextProvider from './containers/App/UserContext.js';
import AppWrapper from './containers/App/AppWrapper.js';
import "../node_modules/@mescius/dspdfviewer/wasmSupportApiServer.js";
import "../node_modules/@mescius/dspdfviewer/wasmSupportApi.js";
import "../node_modules/@mescius/dspdfviewer/DsPdf.js";

getEnvironment().then((envfilepath) => {
	import( `./config/${envfilepath}`).then(() => {
		ReactDOM.render(
			isIE ?
			<div className='ie-msg-container'>
				<div>The UDocs application is not supported on Internet Explorer. Please use either Chrome or FireFox.</div>
			</div> :
			<Router basename='/udocs'>
				<UserContextProvider>
					<AppWrapper/>
				</UserContextProvider>
			</Router>,
			document.getElementById('root')
		);
	});
  });
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
