import React from 'react';
import cx from 'classnames';

import styles from './sortmenu.module.scss';

const SortMenu = (props) => {
    const { show, setShow, setSortOption} = props;

    return (
        <div className={cx(styles['sort-container'], {[styles.hidden]: !show})}>
            <div onClick={() => { setSortOption('TA'); setShow(false)}}>Title A-Z</div>
            <div onClick={() => { setSortOption('TZ'); setShow(false)}}>Title Z-A</div>
            <div onClick={() => { setSortOption('RD'); setShow(false)}}>Revision Date</div>
            {/* <div onClick={() => { setSortOption('MD'); setShow(false)}}>Last Added Date</div> */}
        </div>
    )
}

export default SortMenu;