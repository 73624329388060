
const setSelectedManual = (manualsVar, currentManualVar) => {
    // return (id) => {
    //     let allmanuals = manualsVar();
    //     currentManualVar(allmanuals.find(m => m.id === id));
    // }
    return (currentmanual) => {
        currentManualVar (currentmanual);
    }
}
export default setSelectedManual;