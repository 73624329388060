import { InMemoryCache, makeVar } from "@apollo/client";
import moment from 'moment';

export const cache = new InMemoryCache ({
    typePolicies: {
        Query: {
            fields: {
                search: {
                    read() {
                        return currentSearchVar();
                    }
                },
                recent: {
                    read() {
                        return currentRecentVar();
                    }
                },
                favorites: {
                    read() {
                        return currentFavoriteVar();
                    }
                },
                pins: {
                    read() {
                        return currentPinsVar();
                    }
                },
                localmanuals: {
                    read() {
                        return manualsVar();
                    }
                }, 
                manual: {
                    read() {
                        return currentManualVar();
                    }
                },
                tablecontents: {
                    read() {
                        return tablecontentsVar();
                    }
                },
                reviewer: {
                    read() {
                        return currentReviewerVar();
                    }
                },
                activity: {
                    read() {
                        return currentActivityVar();
                    }
                }
            }
        }
    }
});

/**
 * Set initial values when we create cache variables
 */
const tablecontentsInitialValues = {
        title: 'Configuration Drawings', 
        sections: [
            {
                title: 'Training Information',
                subSections: []
            },
            {
                title: 'Introduction',
                subSections: [
                    {
                        title: 'Configuration',
                        docs: []
                    },
                    {
                        title: 'CUSHION, SEAT COVER, CURTAINS INSTALLATION',
                        docs: []
                    }
                ]
            },
            {
                title: 'Index of Drawings',
                subSections: []
            },
            {
                title: '737',
                subSections: [
                    {
                        title: 'Configuration',
                        docs: []
                    },
                    {
                        title: 'CUSHION, SEAT COVER, CURTAINS INSTALLATION',
                        docs: []
                    },
                    {
                        title: 'EMERGENCY EQUIPMENT',
                        docs: [
                            {
                                title: '737-900ER Emergency Slimline Configuration',
                                subTitle: 'EFF:445-478;801-849;880-899 & 479 D:156DD13757 Rev:E',
                                url: '/19-2560DD15012.pdf'
                            },
                            {
                                title: '737-900ER Emergency Slimline Configuration',
                                subTitle: 'EFF:445-478;801-849;880-899 & 479 D:156DD13757 Rev:E',
                                url: '/ER7160R00550.pdf'
                            },
                            {
                                title: '737-900ER Emergency Slimline Configuration',
                                subTitle: 'EFF:445-478;801-849;880-899 & 479 D:156DD13757 Rev:E',
                                url: '/ER4900R00280C.pdf'
                            },
                            {
                                title: '737-900ER Emergency Slimline Configuration',
                                subTitle: 'EFF:445-478;801-849;880-899 & 479 D:156DD13757 Rev:E',
                                url: '/ER2500R01682.pdf'
                            }
                        ]
                    },
                    {
                        title: 'TESTING INFORMATION',
                        docs: [
                            {
                                title: '737-900ER AOBM ual',
                                subTitle: 'EFF:445-478;801-849;880-899 & 479 D:156DD13757 Rev:E',
                                url: '/AOBM-UAL.pdf'
                            },
                            {
                                title: '737-900ER GMM links',
                                subTitle: 'EFF:445-478;801-849;880-899 & 479 D:156DD13757 Rev:E',
                                url: '/gmm-links.pdf'
                            },
                            {
                                title: '737-900ER MEB video',
                                subTitle: 'EFF:445-478;801-849;880-899 & 479 D:156DD13757 Rev:E',
                                url: '/meb-video.pdf'
                            },
                            {
                                title: '737-900ER Safty 4 videos',
                                subTitle: 'EFF:445-478;801-849;880-899 & 479 D:156DD13757 Rev:E',
                                url: '/safety-4-videos.pdf'
                            }.recent,
                            {
                                title: '737-900ER Techops forms',
                                subTitle: 'EFF:445-478;801-849;880-899 & 479 D:156DD13757 Rev:E',
                                url: '/techops-forms.pdf'
                            }
                        ]
                    },
                ]
            }
        ]
}
const manualsInitialValues = [
    { 
        id: 1,
        title: '737 Component Maintenace Manual', 
        info: { 
            rev: moment(), 
            type: 'PDF', 
            fleet: '737-900', 
            url: '/safety-4-videos.pdf'
        } 
    }, 
    {
        id: 2, 
        title: 'Configuration Drawings', 
        info: { 
            rev: moment(), 
            type: 'PDF', 
            fleet: '737-900', 
            url: '/meb-video.pdf'
        } 
    }, 
    { 
        id: 3,
        title: 'Random Manual 1', 
        info: { 
            rev: moment(), 
            type: 'PDF', 
            fleet: '737-900', 
            url: '/gmm-links.pdf'
        } 
    }, 
    { 
        id: 4,
        title: 'Seat Configuration', 
        info: { 
            rev: moment(), 
            type: 'PDF', 
            fleet: '737-900',
            url: '/19-2500DD15184.pdf' 
        } 
    }
];
const initialManual = null;
const initialSearch = null;
const initialRecent = [];
const initialFavorite = [];
const initialPins = [];
const initialReviewer = [];
const initialActivity = [];

export const tablecontentsVar = makeVar(
    tablecontentsInitialValues
);
export const manualsVar = makeVar(
    manualsInitialValues
);
export const currentManualVar = makeVar(
    initialManual
);
export const currentSearchVar = makeVar(
    initialSearch
);
export const currentRecentVar = makeVar(
    initialRecent
);
export const currentFavoriteVar = makeVar(
    initialFavorite
);
export const currentPinsVar = makeVar(
    initialPins
);
export const currentReviewerVar = makeVar(
    initialReviewer
);
export const currentActivityVar = makeVar(
    initialActivity
);