import { gql } from '@apollo/client';

export const GET_PREPUBLISH_MANUAL = gql`
    query Admin($employeeId: String!, $isCompleteList: Boolean!) {
        admin {
            prepublishedManualList(employeeId: $employeeId, isCompleteList: $isCompleteList) {
                categoryName
                manualName
                isSearchEnabled
                isHtml
                isDocumentCollection
                fleetManuals{
                    revDate
                    manualCode
                    isSuperAdmin
                    previewStatus
                    fleetType}
                engineManuals{  
                    revDate
                    manualCode
                    isSuperAdmin
                    previewStatus
                    engineType}
                vendorManuals{ 
                    revDate
                    manualCode
                    isSuperAdmin
                    previewStatus
                    vendorName}
            }
        }
    }
`;
export const GET_PRE_APPROVAL_STATUS = gql`
    query Admin($employeeId: String!, $manualCode: String!) {
        admin {
            getPreviewApprovalStatus(employeeId: $employeeId, manualCode: $manualCode) {
                comments
                createdBy
                createdDate
                firstName
                imgBase64
                lastName
                manualVersionActivityId
                modifiedBy
                modifiedDate
                previewAdminActivityId
                previewAdminId
                reviewActionDescription
                reviewActionId
            }
        }
    }
`;
export const GET_PRE_ADMINS = gql`
    query Admin($manualCode: String!) {
        admin {
            getPreviewAdmins(manualCode: $manualCode) {
                employeeId
                firstName
                isActive
                isSuperAdmin
                lastName
                modifiedBy
                modifiedDate
                previewAdminId
            }
        }
    }
`;

export const GET_PRE_ACTION = gql`
    query Admin {
        admin {
            getPreviewActions {
                action
                isActive
                previewActionId
            }
        }
    }
`;

export const GET_PRE_ADMINS_IMAGE = gql`
    query Admin($previewAdminActivityId: Int!) {
        admin {
            getPreviewAdminActivityImage(previewAdminActivityId: $previewAdminActivityId) {
                imgBase64
                previewAdminActivityId
                previewAdminActivityImageId
            }
        }
    }
`;

export const GET_MANUAL_REVIEWERS = gql`
    query GetManualReviewers {
        reviewer @client {
            firstName
            lastName
            manualCode
            modifiedDate
            employeeId
            previewAdminId
        }
    }
`;
export const GET_REVIEWER_ACTIVITIES = gql`
    query GetReviewerActivities {
        activity @client {
            comments
            firstName
            imgBase64
            lastName
            manualVersionActivityId
            modifiedDate
            previewAdminActivityId
            previewAdminId
            reviewActionDescription
            reviewActionId
        }
    }
`;