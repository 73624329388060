import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { ViewerContext } from '../../../containers/Viewer/ViewerContext';

import styles from './manualalert.module.scss';
import Icon from '../../Icon/Icon';
import { GET_ALERT } from '../../../operations/queries/alert';
import { mutations } from '../../../operations/mutations';

import { store } from 'react-notifications-component';
import Notification from '../../../components/Notification/Notification';
import 'react-notifications-component/dist/theme.css'

const ManualAlert = (props) => {
    const {  setShow, manualCode } = props;
    const { setViewerLoading, setSelectedDocument } = useContext(ViewerContext);
    const { loading, error, data } = useQuery(GET_ALERT, {variables: { manualCode: manualCode }, fetchPolicy: 'network-only'});

    return (
        !loading ?
        <div className={styles['pin-items-container']}>
            <div className={styles.heading}>
                <div className={styles.alert}><div className={styles.itemEX}>!</div></div>
                <div className={styles.text}>{data && data.alerts && data.alerts.getAlerts.activeAlerts.length>0 ? data.alerts.getAlerts.activeAlerts[0].alertTitle : ''}</div>
                <div onClick={() => setShow(null)} className={styles.close}></div>
            </div>
            {console.log("alert", data)}
            <div className={styles.body}>                    
                {data && data.alerts && data.alerts.getAlerts.activeAlerts.length>0 ? data.alerts.getAlerts.activeAlerts[0].alertDescription : ''}
            </div>
        </div>
        :
         <div className={`${styles['contents-spinner-container']} ${styles['spinner-section']}`}>
         <div className={styles.spinner}></div>
         </div>
    )
}

export default ManualAlert;