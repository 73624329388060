import React, { useState } from 'react';
import styles from './attachimage.module.scss';

const AttachImage = (props) => {
    const { imageattachment, close } = props;
return(
    <div className={styles.attachImage}  style={{maxHeight: props.innerHeight}}>
      <img className={styles.img} src={imageattachment} alt="Only image file can be displayed ..." />
    </div>
)
}
export default AttachImage