import { gql } from '@apollo/client';

export const GET_EMP_PROFILE = gql`
query ProfileQuery($userId: String!) {
    profileQuery   {
        profile(userId: $userId) { 
                activeAlerts{
                    alertDescription
                    alertTitle
                    alertType
                }
                isAdmin
                isSuperAdmin
                isSpeclSuperAdmin
                reviewCount
            }
        }
    }
`;
export const GET_EMP_PREFS = gql`
    query Prefereneces($employeeId: String!, $totalRecent: Int!) {
        userPreferences {
            userFavouriteManuals (employeeId: $employeeId) {
                manualCode
                isActive
                modifiedDate
            }
            recentlyViewedManuals (employeeId: $employeeId, noOfManuals: $totalRecent) {
                manualCode
                isActive
                modifiedDate
            }
        }
    }
`;