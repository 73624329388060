import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { HomeContext } from '../Home/HomeContext';
import styles from './recent.module.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import SearchFilters from '../../components/SearchFilters/SearchFilters';
import LayoutToggle from '../../components/LayoutToggle/LayoutToggle';
import DocTile from '../../components/DocTile/DocTile';
import DocTable from '../../components/DocTable/DocTable';
import { GET_EMP_MANUALS, GET_RECENT_MANUALS, GET_FAVORITE_MANUALS } from '../../operations/queries/manuals';
import { useItemsFilter, useItemsSort } from '../../Hooks/hooks';
import { GET_EMP_PROFILE, GET_EMP_PREFS } from '../../operations/queries/preferences';
import { UserContext } from '../App/UserContext';

const Recent = () => {
    const { userId } = useContext(UserContext);
    const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });
    const [getEmpManuals, { loading, data }] = useLazyQuery(GET_EMP_MANUALS);
    const manualsResults = useQuery(GET_RECENT_MANUALS);
    const favmanuals = useQuery(GET_FAVORITE_MANUALS);

    const { setSelectedPinManualCode, setSelectedAlertManualCode, showGrid, setShowGrid } = useContext(HomeContext);

    const [manuals, setManuals] = useState([]);
    const [search, setSearch] = useState('');
    const [sortopt, setSortopt] = useState('TA');
    //const [showGrid, setShowGrid] = useState(true);
    const [isMobile, setisMobile] = useState(false);
    const handleWindowSizeChange = () => { 
        if (window.innerWidth <= 768) { setisMobile(true); } else {setisMobile(false);}
    }

    useEffect(() => {setSelectedPinManualCode(null); setSelectedAlertManualCode(null);}, []);
    useEffect(() => {
        if (profileinfo.data && profileinfo.data.profileQuery) {
            getEmpManuals({ variables: { employeeId: userId || 'V726082' } });
        }
    }, [profileinfo])
    useEffect(() => {
        const manuals = data ? data.manuals : null;
        const recents = manualsResults && manualsResults.data ? manualsResults.data.recent : null;
        if (manuals && recents) {
            const fullfavs = [];
            recents.forEach(f => {
             //   debugger
                const foundcategory = manuals.getManualsForEmployee.find(m => m.fleetManuals.findIndex(fm => fm.manualCode === f.manualCode) !== -1 || 
                                                                            m.vendorManuals.findIndex(vm => vm.manualCode === f.manualCode) !== -1 || 
                                                                            m.engineManuals.findIndex(em => em.manualCode === f.manualCode) !== -1);
                                                                            
                const foundmanual = foundcategory && foundcategory.categoryName === 'Fleet' ? foundcategory.fleetManuals.find(fm => fm.manualCode === f.manualCode) : 
                                    foundcategory && foundcategory.categoryName === 'Vendor' ? foundcategory.vendorManuals.find(vm => vm.manualCode === f.manualCode) : 
                                    foundcategory && foundcategory.categoryName === 'Engine' ? foundcategory.engineManuals.find(em => em.manualCode === f.manualCode) : null;
                
                if (foundmanual) {
                    fullfavs.push({
                        categoryName: foundcategory.categoryName,
                        manualName: foundcategory.manualName,
                        manualCode: f.manualCode,
                        isHtml: foundcategory.isHtml,
                        isSearchEnabled: foundcategory.isSearchEnabled,
                        hasPins: foundmanual.hasPins,
                        hasAlerts: foundmanual.hasAlerts,
                        uRL: foundmanual.uRL,
                        revDate: foundmanual.revDate, 
                        revision: foundmanual.revision,
                        typeInfo: foundmanual.fleetType || foundmanual.vendorName || foundmanual.engineType || null,
                        fleetType: foundmanual.fleetType,
                        vendorName: foundmanual.vendorName, 
                        engineType: foundmanual.engineType
                    });
                }
            });   
   
            setManuals(fullfavs);
        }
    }, [data, manualsResults])
    useEffect(()=> { 
        
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    
    
    }, []);

    const filterItems = (e) => setSearch(e.target.value);
    const filteredtiles = useItemsSort(
                            useItemsFilter(manuals, search, true, 'manualName'), 
                            sortopt === 'TA' || sortopt === 'TZ' ? 'string' : 'date',  
                            sortopt === 'TZ' ? false : true, true, 
                            sortopt === 'TA' || sortopt === 'TZ' ? 'manualName' : 'revDate');

    const favloading = favmanuals.loading; const faverror = favmanuals.error; const favdata = favmanuals.data;
    return (
        <div className={styles.container}>
            <PageTitle title='Recent' />
            <SearchFilters filterItems={filterItems} sortItems={setSortopt} />
            { isMobile ? null : <LayoutToggle layout={showGrid} setLayout={setShowGrid}/>}                 
            { isMobile ? <div className={styles.tiles}>
                { filteredtiles.map((t, index) => <DocTile key={index} empId={userId || 'V726082'} title={t.manualName} info={t} category={t.categoryName} isFav={favdata.favorites.findIndex(fa => fa.manualCode === t.manualCode) !== -1} isHtmlManual={t.isHtml}  isSearchEnable={t.isSearchEnabled}/>) }
                </div> : showGrid ? <DocTable empId={userId || 'V726082'} rowData={filteredtiles} isFav={favdata.favorites} /> : <div className={styles.tiles}>
                            { filteredtiles.map((t, index) => <DocTile key={index} empId={userId || 'V726082'} title={t.manualName} info={t} category={t.categoryName} isFav={favdata.favorites.findIndex(fa => fa.manualCode === t.manualCode) !== -1} isHtmlManual={t.isHtml}  isSearchEnable={t.isSearchEnabled}/>) }
                            </div>
            }  
        </div>
    )
}

export default Recent;