import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import styles from './AdminPanel.module.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import AdminTile from '../../components/AdminTile/AdminTile';
import { GET_PREPUBLISH_MANUAL } from '../../operations/queries/admin';
import { GET_EMP_PROFILE } from '../../operations/queries/preferences';
import { PreviewContext } from '../PreviewManual/PreviewManualContext';
import { UserContext } from '../App/UserContext';

const AdminPanel = () => {
    const { userId } = useContext(UserContext);
    const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });
    const [getPreviewManuals, { loading:previewloading, data:previewmanual }] = useLazyQuery(GET_PREPUBLISH_MANUAL);
    const [getPreviewManualsAdmin, { loading:previewloadingadmin, data:previewmanualadmin }] = useLazyQuery(GET_PREPUBLISH_MANUAL);

    const [assignedToMe, setAssignedToMe] = useState([]);
    const [premanuals, setpreManuals] = useState([]);
    const [premanualsadmin, setpreManualsAdmin] = useState([]);

    const { manualAdmin, setManualAdmin } = useContext(PreviewContext);

    useEffect(() => {
        if (profileinfo.data && profileinfo.data.profileQuery) {
            getPreviewManuals({ variables: { employeeId: userId || 'V791839', isCompleteList: true } });
            getPreviewManualsAdmin({ variables: { employeeId: userId || 'V791839', isCompleteList: false } });
        }
    }, [profileinfo])

    useEffect(() => {
        if(previewmanual && previewmanual.admin)
            {setpreManuals(previewmanual.admin.prepublishedManualList);}    
    }, [previewmanual])
    useEffect(() => {
        if(previewmanualadmin && previewmanualadmin.admin)
            {setpreManualsAdmin(previewmanualadmin.admin.prepublishedManualList);}    
    }, [previewmanualadmin])

    useEffect(()=>
    {
        const currentVals = [];
        if(premanuals)
        {
            premanuals.map((t, index) => {
                if(assignedToMe) 
                {
                    if(t.categoryName === 'Fleet') 
                    { 
                        if(t.fleetManuals) t.fleetManuals.map((f, findex) => 
                            {
                                const index = assignedToMe.findIndex(z => z.manualCode === f.manualCode);
                                if(index!==-1)
                                    currentVals.push({manualCode: f.manualCode, isSuperAdmin: f.isSuperAdmin, isAssigned: true})
                                else
                                    currentVals.push({manualCode: f.manualCode, isSuperAdmin: f.isSuperAdmin, isAssigned: false})
                        
                        }); 
                    } 
                    else if (t.categoryName === 'Vendor') 
                        { 
                            if(t.vendorManuals) t.vendorManuals.map((v, vindex) => 
                            {
                                const index = assignedToMe.findIndex(z => z.manualCode === v.manualCode);
                                if(index!==-1)
                                    currentVals.push({manualCode: v.manualCode, isSuperAdmin: v.isSuperAdmin, isAssigned: true})
                                else
                                    currentVals.push({manualCode: v.manualCode, isSuperAdmin: v.isSuperAdmin, isAssigned: false})
                            }) 
                        } 
                    else if (t.categoryName === 'Engine')
                        { 
                            if(t.engineManuals) t.engineManuals.map((e, eindex) => 
                            {
                                const index = assignedToMe.findIndex(z => z.manualCode === e.manualCode);
                                if(index!==-1)
                                    currentVals.push({manualCode: e.manualCode, isSuperAdmin: e.isSuperAdmin, isAssigned: true})
                                else
                                    currentVals.push({manualCode: e.manualCode, isSuperAdmin: e.isSuperAdmin, isAssigned: false})
                            })  
                        
                        }  
                }
                else
                {
                    if(t.categoryName === 'Fleet') 
                        { if(t.fleetManuals) t.fleetManuals.map((f, findex) => currentVals.push({manualCode: f.manualCode, isSuperAdmin: f.isSuperAdmin, isAssigned: false})); } 
                    else if (t.categoryName === 'Vendor') 
                        { if(t.vendorManuals) t.vendorManuals.map((v, vindex) => currentVals.push({manualCode: v.manualCode, isSuperAdmin: v.isSuperAdmin, isAssigned: false})) } 
                    else if (t.categoryName === 'Engine')
                        { if(t.engineManuals) t.engineManuals.map((e, eindex) => currentVals.push({manualCode: e.manualCode, isSuperAdmin: e.isSuperAdmin, isAssigned: false}))  }  
                }
            }); 
            setManualAdmin(currentVals);
        }
    },[premanuals, assignedToMe])

    useEffect(()=>
    {
        const currentVals = [];
        if(premanualsadmin)
        {
            premanualsadmin.map((t, index) => {
                if(t.categoryName === 'Fleet') 
                    { if(t.fleetManuals) t.fleetManuals.map((f, findex) => currentVals.push({manualCode: f.manualCode, isSuperAdmin: f.isSuperAdmin})); } 
                else if (t.categoryName === 'Vendor') 
                    { if(t.vendorManuals) t.vendorManuals.map((v, vindex) => currentVals.push({manualCode: v.manualCode, isSuperAdmin: v.isSuperAdmin})) } 
                else if (t.categoryName === 'Engine')
                    { if(t.engineManuals) t.engineManuals.map((e, eindex) => currentVals.push({manualCode: e.manualCode, isSuperAdmin: e.isSuperAdmin}))  }  
            }); 
            setAssignedToMe(currentVals);
        }
    },[premanualsadmin])

    return (
        <div>
            <div className={styles.container}>                
                <PageTitle title='Review Manuals' />
                {                
                    profileinfo.data && profileinfo.data.profileQuery && profileinfo.data.profileQuery.profile.isSuperAdmin ?
                    <div className={styles['sub-section']}>
                        <div className={styles.title}>Prepublished Manuals {premanuals && premanuals.length>0 ? '(' + premanuals.length + ')' : ''} </div>
                        <div className={styles.line}><hr /></div>
                    </div>:null
                }
                 <div className={styles.tiles}>                   
                {   
                    previewloading ?
                    <div className={styles['spinner-section']}>
                        <div className={styles.spinner}></div>
                    </div>
                    :
                    profileinfo.data && profileinfo.data.profileQuery && (profileinfo.data.profileQuery.profile.isSuperAdmin || profileinfo.data.profileQuery.profile.isSpeclSuperAdmin) ?
                    premanuals  ? 
                        premanuals.map((t, index) => t.categoryName === 'Fleet' ?
                        t.fleetManuals ? t.fleetManuals.map((f, findex) => <AdminTile key={index} title={t.manualName} info={f} manualCode={f.manualCode} category={t.categoryName} isUpperClick={true} isHtmlManual={t.isHtml} isSearchEnable={t.isSearchEnabled} isDocumentCollection={t.isDocumentCollection} />) : null : 
                            t.categoryName === 'Vendor' ?
                        t.vendorManuals ? t.vendorManuals.map((v, vindex) => <AdminTile key={index} title={t.manualName} info={v} manualCode={v.manualCode} category={t.categoryName} isUpperClick={true} isHtmlManual={t.isHtml} isSearchEnable={t.isSearchEnabled} isDocumentCollection={t.isDocumentCollection} />) : null : 
                            t.categoryName === 'Engine' ?
                        t.engineManuals ? t.engineManuals.map((e, eindex) => <AdminTile key={index} title={t.manualName} info={e}  manualCode={e.manualCode} category={t.categoryName} isUpperClick={true} isHtmlManual={t.isHtml} isSearchEnable={t.isSearchEnabled} isDocumentCollection={t.isDocumentCollection} />) : null : 
                            null ) : 'nothing yet'  
                    : null  
                }  
                </div>  

                <div className={styles['sub-section']}>
                    <div className={styles.shorttitle}>Assigned to You {assignedToMe && assignedToMe.length>0 ? '(' + assignedToMe.length + ')': ''}</div>
                    <div className={styles.line}><hr /></div>
                </div> 
                <div className={styles.tiles}>                   
                {   
                    previewloadingadmin ?
                    <div className={styles['spinner-section']}>
                        <div className={styles.spinner}></div>
                    </div>
                    :
                    premanualsadmin  ? 
                    premanualsadmin.map((t, index) => t.categoryName === 'Fleet' ?
                        t.fleetManuals ? t.fleetManuals.map((f, findex) => <AdminTile key={index} title={t.manualName} info={f} manualCode={f.manualCode} category={t.categoryName} isUpperClick={false} isHtmlManual={t.isHtml} isSearchEnable={t.isSearchEnabled} isDocumentCollection={t.isDocumentCollection} />) : null : 
                            t.categoryName === 'Vendor' ?
                        t.vendorManuals ? t.vendorManuals.map((v, vindex) => <AdminTile key={index} title={t.manualName} info={v} manualCode={v.manualCode} category={t.categoryName} isUpperClick={false} isHtmlManual={t.isHtml} isSearchEnable={t.isSearchEnabled} isDocumentCollection={t.isDocumentCollection}/>) : null : 
                            t.categoryName === 'Engine' ?
                        t.engineManuals ? t.engineManuals.map((e, eindex) => <AdminTile key={index} title={t.manualName} info={e} manualCode={e.manualCode} category={t.categoryName} isUpperClick={false} isHtmlManual={t.isHtml} isSearchEnable={t.isSearchEnabled} isDocumentCollection={t.isDocumentCollection}/>) : null : 
                            null ) : 'nothing yet'    
                }  
                </div>              
            </div>
        </div>
    )
}

export default AdminPanel;