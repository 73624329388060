import React, { useContext } from 'react';
import ReactDOM from 'react-dom';

import { ModalContext } from './ModalContext';
import ModalHeader from './Header/Header';
import ModalBody from './Body/Body'
import styles from './modal.module.scss';

export const Modal = ({closeModal, children, ...props}) => {
    const modalNode = useContext(ModalContext);

    return modalNode ?
        ReactDOM.createPortal(
            <div className={styles['modal-overlay']}>
                <div className={styles[`modal-container`]}>
                    <ModalHeader title={props.title} closeModal={closeModal} />
                    <ModalBody>{children}</ModalBody>
                </div>
            </div>, 
            modalNode
        ) :
        null
}