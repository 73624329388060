import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { ViewerContext } from '../../../containers/Viewer/ViewerContext';

import styles from './Comments.module.scss';
import Icon from '../../Icon/Icon';
import { GET_EMP_PINS } from '../../../operations/queries/manuals';
import { mutations } from '../../../operations/mutations';

import { store } from 'react-notifications-component';
import Notification from '../../../components/Notification/Notification';
import 'react-notifications-component/dist/theme.css'

const Comments = (props) => {
    const { comments, setShow } = props;
    const history = useHistory();
    const { selectManual } = mutations;
    const { setViewerLoading, setSelectedDocument } = useContext(ViewerContext);

    return (
        <div className={styles['pin-items-container']}>
            <div className={styles.heading}>
                <div>Comments</div>
                <div onClick={() => setShow(null)} className={styles.close}></div>
            </div>
            <div className={styles.body}>
                {                    
                    comments
                }
            </div>
        </div>
    )
}

export default Comments;