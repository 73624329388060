import React, { lazy, Suspense, useMemo } from 'react';
import PropTypes from 'prop-types';

import viewbox from './viewbox';
import './icon.scss';

const Icon = props => {
    const { variant, className, clickable } = props;
    const determineWidthAndHeight = (type, passedValue) => {
        const [, , viewWidth, viewHeight] = viewbox[variant].split(' ');
        const ratio = +(viewHeight / viewWidth).toFixed(2);
        const [, usablePassedValue, unit] = passedValue.match(
            /(\d+\.?\d+)(.+)?/
        );
        if (type === 'width') {
            const returnWidth = (+usablePassedValue / ratio).toFixed(2);
            return `${returnWidth}${unit}`;
        }
        const returnHeight = (+usablePassedValue * ratio).toFixed(2);
        return `${returnHeight}${unit}`;
    }
    const heightAndWidthHandler = (type) => {
        if (type in props) {
            return props[type];
        }
        switch (type) {
        case 'width':
            if ('height' in props) {
            return determineWidthAndHeight(type, props.height);
            }
        // falls through
        case 'height':
            if ('width' in props) {
            return determineWidthAndHeight(type, props.width);
            }
        // falls through
        default:
            return '20px';
        }
    }
    const config = {
        xmlns: 'https://www.w3.org/2000/svg',
        xmlnsXlink: 'https://www.w3.org/1999/xlink',
        viewBox: viewbox[variant],
        width: heightAndWidthHandler('width'),
        height: heightAndWidthHandler('height'),
        ...props,
        className: `core-icon ${className || ''} ${
          clickable ? 'clickable-icon' : ''
        }`
    };
    const IconContent = useMemo(
        () => lazy(() => import(`../../assets/icons/${variant}.js`)),
        [variant]
    );

    return (
        <Suspense fallback={<svg {...config} />}>
          <svg {...config}>
            <IconContent {...config} />
          </svg>
        </Suspense>
    );
}

Icon.defaultProps = {
    className: null,
    clickable: null
}

Icon.propTypes = {
    variant: PropTypes.string.isRequired,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    clickable: PropTypes.string
}

export default Icon;