import React, { useState, useEffect, useRef, useContext } from 'react';
import cx from 'classnames';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import ViewSDKClient from '../../pdfclient/ViewSDKClient';

import { PreviewContext } from './PreviewManualContext';
import { ViewerContext } from '../Viewer/ViewerContext';

import styles from './previewmanual.module.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import Assignment from '../../components/Assignment/Assignment';
import { GET_DOCUMENT_V2 } from '../../operations/queries/documents';
import { GET_CURRENT_MANUAL, GET_FAVORITE_MANUALS } from '../../operations/queries/manuals';
import Icon from '../../components/Icon/Icon';
import Slideout from '../../components/Slideout/Slideout';
import TableContents from '../../components/Slideout/TableContents/TableContents';
import Search from '../../components/Slideout/Search/Search';
import AttachImage from '../../components/Slideout/Image/AttachImage';
import Select from '../../components/Controls/Select/Select';
import { GET_EMP_PROFILE } from '../../operations/queries/preferences';
import { mutations, UPDATE_EMP_FAV, UPDATE_MANUAL_VERSION, UPDATE_PRE_ADMIN_ACTIVITY } from '../../operations/mutations';
import { GET_PRE_APPROVAL_STATUS, GET_PRE_ACTION, GET_PRE_ADMINS, GET_MANUAL_REVIEWERS, GET_REVIEWER_ACTIVITIES} from '../../operations/queries/admin';

//pdf plugin
import { Worker } from '@react-pdf-viewer/core';
import {  MinimalButton, Position, Tooltip, Viewer, SpecialZoomLevel} from '@react-pdf-viewer/core';
import { defaultLayoutPlugin, ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { bookmarkPlugin } from '@react-pdf-viewer/bookmark';
import '@react-pdf-viewer/bookmark/lib/styles/index.css';
import { thumbnailPlugin } from '@react-pdf-viewer/thumbnail';
import '@react-pdf-viewer/thumbnail/lib/styles/index.css';
import { NextIcon, PreviousIcon, RenderSearchProps, searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { fullScreenPlugin } from '@react-pdf-viewer/full-screen';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';
import ShadowDOM from "../../components/ShadowDOM";
import { UserContext } from '../App/UserContext.js';
import config from '../../config/development.js';

//end pdf plugin


const Previewmanual = (props) => {
    const { userId, firstName, lastName } = useContext(UserContext);
    const history = useHistory();
    const manualResult = useQuery(GET_CURRENT_MANUAL);
    const reviewers = useQuery(GET_MANUAL_REVIEWERS);
    const activities = useQuery(GET_REVIEWER_ACTIVITIES);
    const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });
    const previewAction = useQuery(GET_PRE_ACTION);
    const [getPreviewApprovalStatus,{loading: preApprovalStatusLoading, data: preApprovalStatus}]=useLazyQuery(GET_PRE_APPROVAL_STATUS, { fetchPolicy: 'network-only' });
    const [complianceTag, setComplianceTag]=useState(false);
    const [getPreviewAdmins,{loading: previewAdminsLoading, data: previewAdmins}]=useLazyQuery(GET_PRE_ADMINS, { fetchPolicy: 'network-only' });
    const [getDocument, { loading,error, data }] = useLazyQuery(GET_DOCUMENT_V2);

    const [updateManualVersion] = useMutation(UPDATE_MANUAL_VERSION);
    const [savePreviewAdminActivity] = useMutation(UPDATE_PRE_ADMIN_ACTIVITY);
    const { setFavorites, setReviewers, setActivities } = mutations;

    const {  isViewerLoading, setViewerLoading, manualAdmin, setManualAdmin,upperClick, setUpperClick, previewAdminActivityImageId, isSearchEnabled } = useContext(PreviewContext);
    const { selectedDocument, setSelectedDocument, isHtml, setScrollPosition, previousManualCode, setPreviousManualCode, setCurrentSelectedSections, setCurrentSelectedSubSections, pdfWorkerLoading, setPdfWorkerLoading } = useContext(ViewerContext);

    const [isContentSelected, setContentSelected] = useState(true);
    const [isSearchSelected, setSearchSelected] = useState(false);
    const [isBookmarkSelected, setBookmarkSelected] = useState(false);
    const [isViewAttachmentClicked, setViewAttachmentClicked] = useState(false);
    const [isTitleActive, setTitleActive] = useState(true);
    const [windowsize, setWindowSize]= useState(window.innerWidth);
    const [reviewerAttachment, setReviewerAttachment]=useState([]);
    const [viewAttachment, setViewAttachment]=useState();
    const [commnetsField, setCommnets] = useState();
    const [AdminStatus, setAdminStatus]=useState(0);
    const [publishReady, setPublishReady]=useState(false);
    const [htmlBase64, setHtmlBase64]=useState('');
    const [revDetails, setRevDetails] = useState();
    const [tocHeight, setTOCHeight]=useState((window.innerHeight-260)+'px');

        //pdf plugin
        const [urlPath, setUrlPath] =useState("");
        const bookmarkPluginInstance = bookmarkPlugin();
        const { Bookmarks } = bookmarkPluginInstance;
        const defaultLayoutPluginInstance = defaultLayoutPlugin();


        const thumbnailPluginInstance = thumbnailPlugin();
        const { Thumbnails } = thumbnailPluginInstance;
        const searchPluginInstance = searchPlugin();
        const { Search } = searchPluginInstance;
        const fullScreenPluginInstance = fullScreenPlugin();
        const { EnterFullScreenButton } = fullScreenPluginInstance;
        //end pdf plugin

        //pdf plugin
        const [readyToSearch, setReadyToSearch] = useState(false);
        //end pdf plugin

    const handleWindowSizeChange = () => {
        setWindowSize(window.innerWidth);
        setTOCHeight((window.innerHeight-260)+'px');
    }

    const fileToDataUri = (image) => {
        return new Promise((res) => {
          const reader = new FileReader();
          const {type, name, size} = image;
          reader.addEventListener('load', () => {
              res({
                  base64: reader.result,
                  name: name,
                  type,
                  size: size,
              })
          });
          reader.onload = function (e) {
            alert('image has been saved as attachment and it is ready to be submitted.');
        }
          reader.readAsDataURL(image);
        })
      }


    const handleAddAttachmentClick = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const newImagesPromises = []
            for (let i = 0; i < e.target.files.length; i++) {
                if(e.target.files[i]['type'].split('/')[0] === 'image')
                    newImagesPromises.push(fileToDataUri(e.target.files[i]))
                else
                    alert('Please select an image file (.gif, .jpg, .png, etc.)');
            }
            const newImages = await Promise.all(newImagesPromises)
            setReviewerAttachment([...reviewerAttachment, ...newImages])
        }
        e.target.value = "";
    }

    // Create a reference to the hidden file input element
    const hiddenFileInput = React.useRef(null);

    // Programatically click the hidden file input element
    // when the Button component is clicked
    const handleClick = event => {
        hiddenFileInput.current.click();
    };
    // Call a function (passed as a prop from the parent component)
    // to handle the user-selected file
/*    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
    };*/

    const manualsRef = useRef();
    const [selectedManual, setSelectedManual] = useState('');

    const saveCommnetState = (e) => {
        setCommnets(e.target.value);
    }

    //reset table of content scroll position by different manual
    if((manualResult && manualResult.data && manualResult.data.manual))
    {
        if((manualResult.data.manual.manualCode+"pv")!==previousManualCode)
        {
            setScrollPosition(0);
            setCurrentSelectedSections([]);
            setCurrentSelectedSubSections([]);
        }

        setPreviousManualCode(manualResult.data.manual.manualCode+"pv");
    }
    const isSpeclAdmin = profileinfo.data.profileQuery.profile ? profileinfo.data.profileQuery.profile.isSpeclSuperAdmin : false;
    
    if (!manualResult || !manualResult.data || !manualResult.data.manual) history.push('/');

    useEffect(()=> {
        //console.log('profile load',profileinfo.data.profileQuery.profile, manualResult.data.manual , manualAdmin)
        if (profileinfo.data.profileQuery.profile && manualResult.data && manualResult.data.manual && manualAdmin)
        {
            const index = manualAdmin.findIndex(v => v.manualCode === manualResult.data.manual.manualCode);
            //console.log('find manual',index);
            if (index !== -1)
            {
                if((manualAdmin[index].isSuperAdmin || isSpeclAdmin) && upperClick)
                {
                    const astatus =1;
                    setAdminStatus(astatus);
                    getPreviewApprovalStatus({variables: {manualCode: manualResult.data.manual.manualCode, employeeId: ''}});
                    getPreviewAdmins({variables: {manualCode: manualResult.data.manual.manualCode}});
                }
                else if (manualAdmin[index].isAssigned && !upperClick)
                {    const astatus =2;
                    setAdminStatus(astatus)
                    getPreviewApprovalStatus({variables: {manualCode: manualResult.data.manual.manualCode, employeeId: userId}});
                }
                else
                {    const astatus =0;
                    setAdminStatus(astatus)
                    getPreviewApprovalStatus({variables: {manualCode: manualResult.data.manual.manualCode, employeeId: userId}});
                }
            }

        }
    },[manualResult, manualAdmin, profileinfo])

    // useEffect(() => {
    //     if (selectedDocument) {
    //         getDocument({variables: { manualCode: manualResult.data.manual.manualCode, fileName: selectedDocument.fileName, isPreview: true, isHtml: isHtml }});
    //     }
    // }, [selectedDocument])

    // useEffect(() => {
    //     if(!isHtml)
    //     {
    //         if (data && data.contentStore && data.contentStore.viewFile && data.contentStore.viewFile.fileStreamBase64) {
    //             const sdkClient = new ViewSDKClient();
    //             sdkClient.ready().then(() => {
    //                 setViewerLoading(false);
    //                 const pdfdata = sdkClient.base64ToArrayBuffer(data.contentStore.viewFile.fileStreamBase64);
    //                 sdkClient.previewFileUsingFilePromise('pdf-viewer', Promise.resolve(pdfdata), data.contentStore.viewFile.fileName);
    //             });
    //         }
    //         else { setViewerLoading(false) }
    //     }
    //     else
    //     {
    //         if (data && data.contentStore && data.contentStore.viewFile && data.contentStore.viewFile.fileStreamBase64)
    //             {
    //                 //const tempbase64=data.contentStore.viewFile.fileStreamBase64.replace
    //                 setHtmlBase64(data.contentStore.viewFile.fileStreamBase64);
    //                 console.log('html', data.contentStore.viewFile.fileStreamBase64);
    //             }
    //             else { setViewerLoading(false) }
    //     }
    // }, [data])

    useEffect(() => {
        if (selectedDocument) {
            if(!isHtml)
            {
                //setViewerLoading(true);
                setUrlPath(null);
               //pdf plugin
                //fetch(config.REACT_APP_DOC_STREAM + manualResult.data.manual.manualCode+"/"+selectedDocument.fileName + "/false")
                fetch(config.REACT_APP_DOC_PRESIGNEDURL + manualResult.data.manual.manualCode+"/"+selectedDocument.fileName+ "/false")
                .then(res => { console.log("--response--", res); return res.json()})
                        .then((res) => {
                            console.log("=====", res);
                            fetch(res.s3PreSignedUrl)
                            .then(res => res.blob())
                            .then(blob => {
                            var newBlob = new Blob([blob], {type: "application/pdf"})
                            console.log(newBlob);
                            setUrlPath(URL.createObjectURL(newBlob));
              });
            })
              setViewerLoading(false);
            }else{
                getDocument({variables: { manualCode: manualResult.data.manual.manualCode, fileName: selectedDocument.fileName , isPreview: true, isHtml: isHtml, includeComplianceInformation: complianceTag }});
            }
           } else{
                setViewerLoading(false)
            }

        }, [selectedDocument, complianceTag])

    useEffect(() => {
         if(isHtml)
         {

            if (data && data.contentStore && data.contentStore.viewFileV2)
                {
                    setRevDetails(data.contentStore.viewFileV2.revDetails)
                    fetch(config.REACT_APP_DOC_PRESIGNEDURL + manualResult.data.manual.manualCode+"/"+selectedDocument.fileName+ "/false")
                    .then(res => { return res.json()})
                            .then((res) => {
                                fetch(res.s3PreSignedUrl)
                                .then(response => response.text())
                                .then(htmlContent => {
                                  let processedHtml = htmlContent
                                    .replace(/\b/g, '')    // Remove "\b"
                                    .replace(/\n/g, '')    // Remove newlines
                                    .replace(/\r/g, '')    // Remove carriage returns
                                    .replace('<!DOCTYPE html  SYSTEM "about:legacy-compat">', '')
                                    .replace('data:image/jpg; base64, ', 'data:image/jpeg;charset=utf-8;base64,')
                                    .replace(/<br>/g, '<br/>');
                              
                                  if (!complianceTag) {
                                    const parser = new DOMParser();
                                    const doc = parser.parseFromString(processedHtml, 'text/html');
                                    const complianceNodes = doc.querySelectorAll('span.compliance');
                                    complianceNodes.forEach(node => node.remove());
                                    processedHtml = doc.documentElement.innerHTML;
                                  }
                                  processedHtml = processedHtml.replace("<html>", "<html>" + revDetails);
                                  setHtmlBase64(processedHtml);
                                })
                                .catch(error => console.error('Error fetching or processing HTML content:', error));
                    })
                }
            else { setViewerLoading(false) }
        }
    }, [data])


    useEffect(() => {
        //console.log('previewAdmin', previewAdmins)
        const allreviewers = previewAdmins ? previewAdmins.admin.getPreviewAdmins : [];
		if (manualResult.data && manualResult.data.manual) setReviewers([...new Set(allreviewers.map(f => { return { manualCode: manualResult.data.manual.manualCode, firstName: f.firstName, lastName: f.lastName, employeeId: f.employeeId, modifiedDate: f.modifiedDate, previewAdminId: f.previewAdminId } }))]);
 }, [previewAdmins])
    useEffect(() => {
        //console.log('previewStatus', preApprovalStatus)
        const allactivities = preApprovalStatus ? preApprovalStatus.admin.getPreviewApprovalStatus : [];
		setActivities([...new Set(allactivities.map(g => { return { comments: g.comments, firstName: g.firstName, lastName: g.lastName, imgBase64: g.imgBase64, modifiedDate: g.modifiedDate, manualVersionActivityId: g.manualVersionActivityId,
            previewAdminActivityId: g.previewAdminActivityId, previewAdminId: g.previewAdminId, reviewActionDescription: g.reviewActionDescription, reviewActionId: g.reviewActionId } }))]);
        if(activities && activities.data.activity.length>0)
            setCommnets(activities.data.activity[0].comments);

 }, [preApprovalStatus])

    useEffect(() => {
        //check if all reviewer approve manual
        if(activities && activities.data.activity.length>0)
        {
            if(AdminStatus===1)
            {
                if(reviewers.data.reviewer.length>0){
                    setPublishReady(true);
                    reviewers.data.reviewer.map(j => {
                        const index = activities.data.activity.findIndex(v => v.previewAdminId === j.previewAdminId);
                        if(index===-1)  setPublishReady(false);
                        else
                        {
                            if(activities.data.activity[index].reviewActionDescription !== 'Approve')
                                setPublishReady(false);
                        }
                    });
                }
            }
            //if (AdminStatus===2)
            setCommnets(activities.data.activity[0].comments);
            setCommnets('')
        }
        else
            setCommnets('');
    },[activities, reviewers])

    useEffect(()=> {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    //const manuals = favmanuals.data && favmanuals.data.favorites ? favmanuals.data.favorites.map(m => m.manualName) : [];
    const actions = previewAction.data && previewAction.data.admin ? previewAction.data.admin.getPreviewActions.map(p=>p.action) :[];
    // const isSpeclAdmin = profileinfo.data.profileQuery.profile ? profileinfo.data.profileQuery.profile.isSpeclSuperAdmin : false;
    //const isFavorite = manualResult && manualResult.data && favmanuals.data && favmanuals.data.favorites.findIndex(f => f.manualCode === manualResult.data.manual.manualCode) !== -1 ? true : false;

    const handleSubmitClick = () =>{
        const imgBase64 = reviewerAttachment.length>0 ? reviewerAttachment[0].base64 : activities && activities.data.activity.length>0 && activities.data.activity[0].imgBase64 ? activities.data.activity[0].imgBase64 : '';
        const manualCode = manualResult && manualResult.data.manual ? manualResult.data.manual.manualCode : null;
        const previewAdminActivityId = preApprovalStatus && preApprovalStatus.admin.getPreviewApprovalStatus.length>0 ? preApprovalStatus.admin.getPreviewApprovalStatus[0].previewAdminActivityId : 0;
        const employeeId = userId || 'V726082';
        {/*console.log('before save', activities, imgBase64, manualCode, previewAdminActivityId, employeeId, selectedManual, commnetsField)*/}
        savePreviewAdminActivity({variables: { comments: commnetsField, imgBase64: imgBase64, manualCode: manualCode, previewAdminActivityImageId: previewAdminActivityImageId, previewAdminActivityId: previewAdminActivityId, employeeId: employeeId,
                                        reviewActionId: selectedManual==='Approve' ? 1 : 2, firstName: firstName, lastName: lastName }});

        if(activities.data.activity.length>0)
        {
            const updateActivity=[{comments: commnetsField, firstName: activities.data.activity[0].firstName, lastName: activities.data.activity[0].lastName, imgBase64: imgBase64, modifiedDate: activities.data.activity[0].modifiedDate, manualVersionActivityId: activities.data.activity[0].manualVersionActivityId,
                previewAdminActivityId: activities.data.activity[0].previewAdminActivityId, previewAdminId: activities.data.activity[0].previewAdminId, reviewActionDescription: selectedManual, reviewActionId: selectedManual==='Approve' ? 1 : 2 }];
            setActivities(updateActivity);
        }
        else{
            const updateActivity=[{comments: commnetsField, firstName: firstName, lastName: lastName, imgBase64: imgBase64, modifiedDate: new Date(), manualVersionActivityId: 0,
                previewAdminActivityId: 0, previewAdminId: 0, reviewActionDescription: selectedManual, reviewActionId: selectedManual==='Approve' ? 1 : 2 }];
            setActivities(updateActivity);
        }
        setReviewerAttachment([]);
    }

    const handlePublishClick = (e, isPublish) =>{
        const manualCode = manualResult && manualResult.data.manual ? manualResult.data.manual.manualCode : null;
        updateManualVersion({variables: { manualCode: manualCode, isApproved: isPublish, firstName: firstName, lastName: lastName }});

        history.push('/');
    }

    const handlePrintClick=()=>{
        var pwa = window.open('about:blank', '', '');
        pwa.document.write(htmlBase64);
        pwa.print();
        pwa.close();
        //window.open('_blank', 'noopener noreferrer')
    }

    const handleDocumentLoad = (e) => {
        const { activateTab } = defaultLayoutPluginInstance;
        setPdfWorkerLoading(false);
        // Activate the bookmark tab whose index is `1` only for MEL manual
        //if(manualResult.data.manual.manualName==='MEL')
            activateTab(1);
    };

    return (
        <>
        <div className={styles.rectangle}></div>
        {
            manualResult && manualResult.data && manualResult.data.manual ?
            <div>
                <div className={cx(styles.container, {[styles.containerHTML]: !isHtml || htmlBase64.length<=0}) }>
                    <div className={styles.history}></div>
                    <div className={styles.actions}>
                        <div className={styles.textual}>
                            <PageTitle title={manualResult.data.manual.manualName} />
                            <div className={styles.info}>
                                        <div className={styles.statusholder}>
                                            <div>Prepublished</div>
                                        </div>
                            </div>

                        </div>
                        <div className={styles.buttons}>
                            <div className={cx(styles.button, {[styles.active]: isContentSelected})} onClick={() => {setContentSelected(!isContentSelected); setSearchSelected(false); setBookmarkSelected(false);setViewAttachmentClicked(false);}}>
                                <div className={`${styles.icon} ${styles.contents}`}><Icon variant='document' width='30px' height='40px' clickable='false' /></div>
                                <div className={styles.text}>Table of contents</div>
                            </div>
                            {/*
                                isSearchEnabled ?
                            <div className={cx(styles.button, {[styles.active]: isSearchSelected})} onClick={() => {setSearchSelected(!isSearchSelected); setContentSelected(false); setBookmarkSelected(false);setViewAttachmentClicked(false);}}>
                                <div className={styles.icon}><Icon variant='search' width='30px' height='40px' clickable='false' /></div>
                                <div className={styles.text}>TOC Search</div>
                            </div>
                            : null
                            */}
                            <div className={cx(styles['slideout'], {[styles['slideout-view']]: isContentSelected})}>
                                {   isContentSelected ?
                                    <Slideout title='Table of Contents' close={() => setContentSelected(false)}  switchtocsearch={()=>setSearchSelected(true)}>
                                        <TableContents manualCode={manualResult.data.manual.manualCode} isPreview={true} setSelectedDocument={setSelectedDocument} setViewerLoading={setViewerLoading} isHtmlManual={isHtml} innerHeight={tocHeight} close={() => setContentSelected(false)} />
                                    </Slideout>
                                    : null
                                }
                            </div>
                            <div className={cx(styles['slideout'], {[styles['slideout-view']]: isSearchSelected})}>
                                {   isSearchSelected ?
                                    <Slideout title='TOC Search' close={() => setSearchSelected(false)}>
                                        <Search manualCode={manualResult.data.manual.manualCode} isPreview={true} setSelectedDocument={setSelectedDocument} setViewerLoading={setViewerLoading} innerHeight={tocHeight} close={() => setSearchSelected(false)} switchtocontent={()=>setContentSelected(true)}/>
                                    </Slideout>
                                    : null
                                }
                            </div>
                            <div className={cx(styles['slideout'], {[styles['slideout-view']]: isViewAttachmentClicked})}>
                                {   isViewAttachmentClicked ?
                                    <Slideout title='View Attachment' close={() => setViewAttachmentClicked(false)}>
                                        <AttachImage imageattachment={viewAttachment} innerHeight={tocHeight} close={() => setViewAttachmentClicked(false)} />
                                    </Slideout>
                                    : null
                                }
                            </div>
                        </div>
                        {
                               selectedDocument && isHtml ?
                        <div className={styles.titlebanner}>
                            {/*<div className={styles.title}>
                                {bannerSubTitle}
                                <div className={styles.subjecttext}>{bannerDetail}</div>
                    </div>*/}

                            <div className={styles.titlebannericon}>
                                {isHtml ?
                                <div className={styles.bannericon} onClick={() => handlePrintClick()}><Icon variant='print' width='30px' height='34px' clickable={true}/></div>
                                :null}
                                {/*
                                    !isHtml ?
                                    <div className={styles.icon} onClick={() => window.open(`/udocs/pdfviewer?manualCode=${manualResult.data.manual.manualCode}&fileName=${selectedDocument.fileName}&isPreview=${false}&title=${bannerDetail}`, '_blank', 'noopener noreferrer')}><Icon variant='popout-gray' width='40px' height='40px' clickable={true}/></div>
                                    :
                                    <div className={styles.icon} onClick={() => window.open(`/udocs/htmlviewer?manualCode=${manualResult.data.manual.manualCode}&fileName=${selectedDocument.fileName}&isPreview=${false}&title=${bannerDetail}`, '_blank', 'noopener noreferrer')}><Icon variant='popout-gray' width='40px' height='40px' clickable={true}/></div>
                                */}
                            </div>

                        </div>
                        : null
                    }

                    </div>

                    { !isHtml && (window.navigator.userAgent.indexOf('Firefox') > -1) ? <div className={styles['firefox-warning']}><sup>*</sup>Printing from the PDF Viewer is not fully supported in Firefox.</div> :  null}
                    {
                        isHtml ?
                            loading ?
                                <div className={styles['spinner-section']}>
                                    <div className={styles.spinner}></div>
                                </div>
                                : (
                                    <ShadowDOM>
                                        <div dangerouslySetInnerHTML={{ __html: htmlBase64 }}></div>
                                    </ShadowDOM>
                                )
                        :
                        <div id='pdf-viewer'>
                        {
                            isViewerLoading ? <div className={styles['pdf-loading']}><div>Viewer loading please wait...</div></div> :
                            <div className={styles["pdf-viewer"]}>
                                            <div   style={{
                                                //border: '1px solid rgba(0, 0, 0, 0.3)',
                                                height: '735px',
                                                width: '100%',
                                            }} >
                                            {
                                                pdfWorkerLoading?
                                                <div className={styles['spinner-section']}>
                                                    <div className={styles.spinner}></div>
                                                </div>
                                                :null
                                            }
                                                {urlPath && <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.16.105/pdf.worker.min.js">


                                                    <div
                                                        style={{
                                                            border: '1px solid rgba(0, 0, 0, 0.3)',
                                                            display: 'flex',
                                                            height: '100%',
                                                        }}
                                                    >

                                                        <div  style={{ flex: 1 }}>
                                                            <Viewer fileUrl={urlPath} plugins={[defaultLayoutPluginInstance,
                                                                 bookmarkPluginInstance,
                                                                  thumbnailPluginInstance,
                                                                   searchPluginInstance]} onDocumentLoad={handleDocumentLoad} defaultScale={SpecialZoomLevel.PageFit}/>
                                                        </div>
                                                    </div>


                                                </Worker>}

                                            </div>
                                        </div>
                        }
                        </div>
                    }
                </div>
                <div className={styles.rightbox}>
                        {
                            isHtml ?
                                <div className={styles.comptag}>View Compliance Tag
                                <Icon variant={complianceTag ? 'toggle-on' : 'toggle-off'} width='36px' height='20px' clickable='true' onClick={() => {setComplianceTag(!complianceTag);}}/>
                                </div>
                            : null
                        }
                        </div>
                {
                    AdminStatus===1 && upperClick ?
                <div className={styles.assignmentpanel}>
                    <div className={styles.toptitle}>
                        <div className={cx(styles.title, {[styles.inactivetitle]: isTitleActive}) } onClick={() => isTitleActive ? setTitleActive(!isTitleActive):setTitleActive(isTitleActive) }>Assign
                            <div className={cx({[styles.activeline]: !isTitleActive}) }></div></div>
                        <div className={cx(styles.title, {[styles.inactivetitle]: !isTitleActive}) } onClick={() => isTitleActive ? setTitleActive(isTitleActive):setTitleActive(!isTitleActive) }>Publishing Process
                            <div className={cx({[styles.activeline]: isTitleActive}) }></div></div>
                        <div className={styles.baseline}></div>
                        {
                            !isTitleActive ?
                            //preApprovalStatusLoading ? <div>Viewers loading please wait...</div> :
                                reviewers && reviewers.data.reviewer ?
                                <div className={styles.subtitle}>Current Assignment
                                    <div>
                                        {reviewers.data.reviewer.map((d, i) => <Assignment key={i} info={d} isSimple={false} isAssign={!isTitleActive} showAttachment={isViewAttachmentClicked} setShowAttachment={setViewAttachmentClicked} setAttachment={setViewAttachment} setContentSelected={setContentSelected} setSearchSelected={setSearchSelected}/> )}
                                    </div>
                                </div>: null
                            :
                            //previewAdminsLoading ? <div>Activities loading please wait...</div> :
                                activities && activities.data.activity ?
                                <div className={styles.subtitle}>Reviewer Approval Status
                                    <div>
                                        {activities.data.activity.map((d, i) => <Assignment key={i} info={d} isSimple={false} isAssign={!isTitleActive} showAttachment={isViewAttachmentClicked} setShowAttachment={setViewAttachmentClicked} setAttachment={setViewAttachment} setContentSelected={setContentSelected} setSearchSelected={setSearchSelected}/> )}
                                    </div>
                                </div>: null
                        }
                    </div>
                    {
                        isTitleActive || isSpeclAdmin ?
                        <div className={styles.bottomtitle}>
                            <div>Publish Manual to UDocs?</div>
                            <div className={styles.item}>
                                <input
                                    type='button'
                                    value='Publish'
                                    onClick={(e) => {if (window.confirm('Are you sure you wish to PUBLISH this manual?'))
                                        handlePublishClick(e, true)}}
                                    disabled={!publishReady}/>

                                <input
                                    type='button'
                                    value='Reject'
                                    onClick={(e) => {if (window.confirm('Are you sure you wish to REJECT this manual?'))
                                        handlePublishClick(e, false)}}
                                />
                            </div>
                        </div> : null
                        /*<div className={styles.bottomtitle}>
                            <div>Add Assignments
                                    <div className={styles.selection}>
                                        <div className={styles.item}>
                                        <Select selectref={manualsRef} title='Assign To' loading={loading} error={error} items={manuals} type='typeahead' selectedItem={selectedManual} setSelectedItem={setSelectedManual} />                                    </div>
                                    </div>
                            </div>
                            <div className={styles.item}>
                                <input
                                    type='button'
                                    value='Assign'
                                     />
                            </div>
                    </div>  */
                    }
                </div>
                : AdminStatus===2 || (AdminStatus===1 && !upperClick )
                ?
                <div className={styles.assignmentpanel}>
                    <div className={styles.toptitle}>
                        <div className={styles.reviewertitle}>Review Status
                            <div className={styles['activeline']}></div></div>
                        <div className={styles.baseline}></div>
                        <div className={styles.subtitlereviewer}>Your Current Review Status
                            <div>
                            {
                                activities && activities.data.activity ?
                                activities.data.activity.map((d, i) => <Assignment key={i} info={d} isSimple={false} isAssign={!isTitleActive} showAttachment={isViewAttachmentClicked} setShowAttachment={setViewAttachmentClicked} setAttachment={setViewAttachment} setContentSelected={setContentSelected} setSearchSelected={setSearchSelected}/> )
                                :null
                            }
                            </div>
                        </div>

                    </div>
                    <div className={styles.bottomtitle}>
                            <div>Approve/Deny Revision
                                    <div className={styles.selection}>
                                        <div className={styles.item}>
                                        <Select selectref={manualsRef} title='Action' loading={loading} error={error} items={actions} selectedItem={selectedManual} setSelectedItem={setSelectedManual} />
                                        </div>
                                    </div>
                            </div>
                            <div className={styles.reviewnote}>Review Note
                                {<div className={styles.attachment} onClick={handleClick}>
                                    <input type="file" accept="image/*" onChange={handleAddAttachmentClick} ref={hiddenFileInput} style={{display: 'none'}}/>
                                    <div className={styles.linktext}>+ Add Attachment</div>
                                    <div className={styles.warningmessage}>Note: Only image attachment is accepted</div>
                                 </div>}
                            </div>

                            <div className={styles.type}><textarea name="note" rows={9} cols={Math.round(windowsize*0.235/7.859)} value={commnetsField} maxLength={500}
                             onChange={(e) => saveCommnetState(e)}/></div>

                            <div className={cx(styles.item, styles.singleitem)}>
                                <input
                                    type='button'
                                    value='Submit'
                                    onClick={ (e) => {if (window.confirm('Are you sure you wish to SUBMIT this manual?')) handleSubmitClick()}}
                                    disabled={selectedManual.length===0}/>
                            </div>
                    </div>
                </div>
                : null
                }

            </div>
            : null
        }
        </>
    )
}

export default Previewmanual;
