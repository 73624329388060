import React from 'react';
import Icon from '../Icon/Icon';
import styles from './slideout.module.scss';

const Slideout = (props) => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.title}>{props.title}</div>
                {
                    props.title === 'Table of Contents' ?
                <div className={styles.icon} onClick={()=>{props.close();props.switchtocsearch();}}><Icon variant='search' width='24px' height='24px' clickable='false' /></div>
                : null
                }
            </div>
            <div className={styles.body}>
                {props.children}
            </div>
        </div>
    )
}

export default Slideout;