import React, { useState, useEffect, useRef, useContext } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import cx from 'classnames';

import { HomeContext } from '../Home/HomeContext';

import styles from './home.module.scss';
import PageTitle from '../../components/PageTitle/PageTitle';
import DocTile from '../../components/DocTile/DocTile';
import Alert from '../../components/Alert/Alert';
import Select from '../../components/Controls/Select/Select';
import { GET_EMP_MANUALS, GET_CURRENT_SEARCH, GET_RECENT_MANUALS, GET_FAVORITE_MANUALS } from '../../operations/queries/manuals';
import { mutations } from '../../operations/mutations';
import { UserContext } from '../App/UserContext';
import { GET_EMP_PROFILE } from '../../operations/queries/preferences';

const Home = () => {
    const { userId } = useContext(UserContext);
    const profileinfo = useQuery(GET_EMP_PROFILE, {
		variables: {
		  userId: userId
		}
	  });
    const [getEmpManuals, { loading, error, data }] = useLazyQuery(GET_EMP_MANUALS);

    const { selectSearch } = mutations;

    const [selectedManual, setSelectedManual] = useState('');
    const [recmanuals, setRecManuals] = useState([]);

    const [fleetDisplay, setFleetDisplay] = useState('');
    const [selectedFleets, setSelectedFleets] = useState([]);
    const [vendorDisplay, setVendorDisplay] = useState('');
    const [selectedVendors, setSelectedVendors] = useState([]);
    const [engineDisplay, setEngineDisplay] = useState('');
    const [selectedEngines, setSelectedEngines] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const { setSelectedPinManualCode, setSelectedAlertManualCode } = useContext(HomeContext);

    useEffect(() => {setSelectedPinManualCode(null); setSelectedAlertManualCode(null);}, []);
    useEffect(() => {
        if (profileinfo.data && profileinfo.data.profileQuery) {
            getEmpManuals({ variables: { employeeId:userId || 'V726082'  } });
        }
    }, [profileinfo])
    useEffect(() => {
        const manualinfo = data && data.manuals && data.manuals.getManualsForEmployee ?
                           data.manuals.getManualsForEmployee.find(m => m.manualName === selectedManual) :
                           null;
        setSelectedCategory(manualinfo); setFleetDisplay(''); setVendorDisplay(''); setEngineDisplay('');
        setSelectedFleets(''); setSelectedVendors(''); setSelectedEngines('');
    }, [selectedManual]);
    useEffect(() => {
        if (selectedCategory || selectedFleets || selectedVendors || selectedEngines) handleSearch();
    }, [selectedCategory, selectedFleets, selectedVendors, selectedEngines])

    const manualsRef = useRef(); const fleetsRef = useRef();
    const vendorsRef = useRef(); const enginesRef = useRef();

    const handleSearch = () => {
        if (selectedFleets.length === 0 && selectedVendors.length === 0 && selectedEngines.length === 0) selectSearch(selectedCategory);
        else {
            const manuals = selectedCategory.categoryName === 'Fleet' ? selectedCategory.fleetManuals.filter(m => selectedFleets.includes(m.fleetType)) :
                            selectedCategory.categoryName === 'Vendor' ? selectedCategory.vendorManuals.filter(m => selectedVendors.includes(m.vendorName)) :
                            selectedCategory.categoryName === 'Engine' ? selectedCategory.engineManuals.filter(m => selectedEngines.includes(m.engineType)) : [];

            const updatedCategory = {...selectedCategory};
            if (selectedCategory.categoryName === 'Fleet') updatedCategory.fleetManuals = [...manuals];
            if (selectedCategory.categoryName === 'Vendor') updatedCategory.vendorManuals = [...manuals];
            if (selectedCategory.categoryName === 'Engine') updatedCategory.engineManuals = [...manuals];

            selectSearch(updatedCategory);
        }
        // history.push('/search');
    }
 //  debugger;
    const currentsearch = useQuery(GET_CURRENT_SEARCH);
    const searchdata = currentsearch && currentsearch.data ? currentsearch.data : null;
    const manuals = data && data.manuals && data.manuals.getManualsForEmployee ? data.manuals.getManualsForEmployee.filter(m => m.fleetManuals.length > 0).map(m => m.manualName) : [];

    const recentmanuals = useQuery(GET_RECENT_MANUALS);
    const favmanuals = useQuery(GET_FAVORITE_MANUALS);

    useEffect(() => {
        const manuals = data ? data.manuals : null;
        const recents = recentmanuals && recentmanuals.data ? recentmanuals.data.recent : null;
        if (manuals && recents) {
            const fullfavs = recents.map(f => {
                const foundcategory = manuals.getManualsForEmployee.find(m => m.fleetManuals.findIndex(fm => fm.manualCode === f.manualCode) !== -1 ||
                        m.vendorManuals.findIndex(vm => vm.manualCode === f.manualCode) !== -1 ||
                                                                            m.engineManuals.findIndex(em => em.manualCode === f.manualCode) !== -1);

                const foundmanual = foundcategory && foundcategory.categoryName === 'Fleet' ? foundcategory.fleetManuals.find(fm => fm.manualCode === f.manualCode) :
                                    foundcategory && foundcategory.categoryName === 'Vendor' ? foundcategory.vendorManuals.find(vm => vm.manualCode === f.manualCode) :
                                    foundcategory && foundcategory.categoryName === 'Engine' ? foundcategory.engineManuals.find(em => em.manualCode === f.manualCode) : [];
                return foundcategory && foundmanual ?
                {
                    categoryName: foundcategory.categoryName,
                    manualName: foundcategory.manualName,
                    manualCode: f.manualCode,
                    isHtml: foundcategory.isHtml,
                    isSearchEnabled: foundcategory.isSearchEnabled,
                    isDocumentCollection: foundcategory.isDocumentCollection,
                    hasPins: foundmanual.hasPins,
                    hasAlerts: foundmanual.hasAlerts,
                    uRL: foundmanual.uRL,
                    isF117Manual:foundmanual.isF117Manual,
                    revDate: foundmanual.revDate,
                    revision: foundmanual.revision,
                    typeInfo: foundmanual.fleetType || foundmanual.vendorName || foundmanual.engineType || null,
                    fleetType: foundmanual.fleetType,
                    vendorName: foundmanual.vendorName,
                    engineType: foundmanual.engineType
                } : null;
            });
            setRecManuals(fullfavs.filter(f => f !== null));
        }
    }, [data, recentmanuals])

    const favloading = favmanuals.loading; const faverror = favmanuals.error; const favdata = favmanuals.data;

    console.log('recent manuals', recentmanuals)
    console.log(searchdata)
    return (
        <div className={styles.container}>
            <div className={styles.statement}>
                <div className={styles.statementtitle}>Export Statement</div>
                <div>Certain information contained in this Portal must not be accessed by non-US citzens or non-US permanent residents who are nationals of Iran, Syria, Cuba, Sudan, and North Korea, without a license issued under Export Administration Regulations. If you believe that information on this Portal can be accessed by such persons, please contact your United Airlines representative immediately. </div>
                <div className={styles.statementtitle}>Proprietary Statement</div>
                <div>This web site contains proprietary information which is the property of United. Unauthorized distribution, copying, or other use is prohibited. </div>
            </div>

            <div>
                {
                    profileinfo.data && profileinfo.data.profileQuery && profileinfo.data && profileinfo.data.profileQuery.profile.activeAlerts.length>0 ?
                    <>
                    { profileinfo.data.profileQuery.profile.activeAlerts.map((t, index) => <Alert key={index} alertTitle={t.alertTitle} alertType={t.alertType} alertDescription={t.alertDescription} isManualAlert={false} />) }
                    </>
                    : null
                }
            </div>
            <div className={styles['sub-section']}>
                <div className={styles.title}>Browse Manuals</div>
                <div className={styles.line}><hr /></div>
            </div>
            <div className={styles.selection}>
                <div className={styles.item}>
                    <Select selectref={manualsRef} title='Manuals' loading={loading} error={error} items={manuals} type='typeahead' selectedItem={selectedManual} setSelectedItem={setSelectedManual} />
                </div>
                {
                    selectedCategory && selectedCategory.categoryName === 'Fleet' ?
                        <div className={styles.item}><Select selectref={fleetsRef} title='Fleets' items={selectedCategory.fleetManuals ? selectedCategory.fleetManuals.map(f => f.fleetType) : []} type='multi' selectedItems={selectedFleets} setSelectedItems={setSelectedFleets} selectedItem={fleetDisplay} setSelectedItem={setFleetDisplay} /></div>
                    : selectedCategory && selectedCategory.categoryName === 'Vendor' ?
                        <div className={styles.item}><Select selectref={vendorsRef} title='Vendors' items={selectedCategory.vendorManuals ? selectedCategory.vendorManuals.map(v => v.vendorName) : []} type='multi' selectedItems={selectedVendors} setSelectedItems={setSelectedVendors} selectedItem={vendorDisplay} setSelectedItem={setVendorDisplay} /></div>
                    : selectedCategory && selectedCategory.categoryName === 'Engine' ?
                        <div className={styles.item}><Select selectref={enginesRef} title='Engines' items={selectedCategory.engineManuals ? selectedCategory.engineManuals.map(e => e.engineType) : []} type='multi' selectedItems={selectedEngines} setSelectedItems={setSelectedEngines} selectedItem={engineDisplay} setSelectedItem={setEngineDisplay} /></div>
                    : null
                }
            </div>
            <div className={styles.tiles}>
            {
                searchdata && searchdata.search ?
                    searchdata.search.categoryName === 'Fleet' ?
                        searchdata.search.fleetManuals.map((f, index) => <DocTile key={index} empId={userId || 'V726082'} title={searchdata.search.manualName} info={f} manualCode={f.manualCode} category={searchdata.search.categoryName} isFav={favdata.favorites.findIndex(fa => fa.manualCode === f.manualCode) !== -1} isHtmlManual={searchdata.search.isHtml} isSearchEnable={searchdata.search.isSearchEnabled} isDocumentCollection={searchdata.search.isDocumentCollection} />) :
                    searchdata.search.categoryName === 'Vendor' ?
                        searchdata.search.vendorManuals.map((v, index) => <DocTile key={index} empId={userId || 'V726082'} title={searchdata.search.manualName} info={v} manualCode={v.manualCode} category={searchdata.search.categoryName} isFav={favdata.favorites.findIndex(fa => fa.manualCode === v.manualCode) !== -1} isHtmlManual={searchdata.search.isHtml} isSearchEnable={searchdata.search.isSearchEnabled} isDocumentCollection={searchdata.search.isDocumentCollection}/>) :
                    searchdata.search.categoryName === 'Engine' ?
                        searchdata.search.engineManuals.map((e, index) => <DocTile key={index} empId={userId || 'V726082'} title={searchdata.search.manualName} info={e} manualCode={e.manualCode} category={searchdata.search.categoryName} isFav={favdata.favorites.findIndex(fa => fa.manualCode === e.manualCode) !== -1} isHtmlManual={searchdata.search.isHtml} isSearchEnable={searchdata.search.isSearchEnabled} isDocumentCollection={searchdata.search.isDocumentCollection} />) :
                    null : null
            }
            </div>
            <div className={styles['sub-section']}>
                <div className={styles.title}>Recently Viewed</div>
                <div className={styles.line}><hr /></div>
            </div>
            <div className={styles.tiles}>
            { recmanuals.map((t, index) => <DocTile key={index} empId={userId || 'V726082'} title={t.manualName} info={t} manualCode={t.manualCode} category={t.categoryName} isFav={favdata.favorites.findIndex(fa => fa.manualCode === t.manualCode) !== -1}  isHtmlManual={t.isHtml} isSearchEnable={t.isSearchEnabled} isDocumentCollection={t.isDocumentCollection}/>) }
            </div>
            {/* <div className={styles['button-section']}>
                <input type='button' value='Search' onClick={() => handleSearch()} disabled={!selectedCategory} />
            </div> */}
        </div>
    )
}

export default Home;
