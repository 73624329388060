import { useRef, useEffect } from 'react';
import React from 'react';
import ReactDOM from 'react-dom';
import config from '../../config/development';
import {DsPdfViewer} from '@mescius/dspdfviewer';

export default function DsViewer(props) {
  const inputRef = useRef(null);
  let viewer;

  useEffect(() => {

    
      if(!viewer) {
        viewer=createViewer(inputRef.current) ;
       console.log("openpdf", props.fileUrl)
       props.viewerLoaded()
      }

      viewer.open(props.fileUrl);
      //viewer.open("41-1070-Tow safety checklist.pdf");
     
   
    
  }, []);

  return <div ref={inputRef}  />
}

function createViewer(host){
  DsPdfViewer.LicenseKey=config.REACT_APP_PDF_API_LICENSE;
  var viewer;
  viewer = new DsPdfViewer(host,{
    workerSrc: "node_modules/@mescius/dspdfviewer/dspdfviewer.worker.js",
    supportApi: {
      // apiUrl: config.REACT_APP_API_URL_BASE +"/formpdf",      
      implementation: new window.WasmSupportApi()
      //token: "support-api-demo-net-core-token-2021",
      //webSocketUrl: config.REACT_APP_API_URL_BASE + "/signalr"
  }
  });
  // viewer.toolbar.addItem({
  //     key: 'custom-save',
  //     icon: { type: 'svg', content: React.createElement('svg', { xmlns: 'http://www.w3.org/2000/svg', version: '1.1', width: '24', height: '24', viewBox: '0 0 24 24', fill: '#ff0000' }, React.createElement('path', { d: 'M20.913 9.058c0.057-0.26 0.087-0.531 0.087-0.808 0-2.071-1.679-3.75-3.75-3.75-0.333 0-0.657 0.044-0.964 0.125-0.581-1.813-2.28-3.125-4.286-3.125-2.047 0-3.775 1.367-4.32 3.238-0.533-0.155-1.097-0.238-1.68-0.238-3.314 0-6 2.686-6 6s2.686 6 6 6h3v4.5h6v-4.5h5.25c2.071 0 3.75-1.679 3.75-3.75 0-1.845-1.333-3.379-3.087-3.692zM13.5 15v4.5h-3v-4.5h-3.75l5.25-5.25 5.25 5.25h-3.75z' })) },
  //     label: 'test',
  //     title: 'Save document',
  //     enabled: false,
  //     action() {
  //       // Save changes on server:
  //       console.log('saving');
  //       console.log(viewer.fileData);
  //     },
  //     onUpdate() {
  //       return {
  //         enabled: viewer.hasDocument,
  //         title: 'Save changes'
  //       };
  //     }
  //   });

    // viewer.toolbarLayout.viewer.default.splice(0, 0, 'custom-save');
    // viewer.toolbarLayout.viewer.mobile.splice(0, 0, 'custom-save');
     //viewer.addDefaultPanels();
     viewer.addThumbnailsPanel();
     
    viewer.toolbarLayout.viewer = { 
      default: ["$navigation", "$split", "text-selection", "pan", "$zoom", "$fullscreen", "download", "save", "print"],
      mobile: ["save", "$navigation", "$split", "text-selection", "pan", "$zoom", "$fullscreen", "download", "print", "rotate", "view-mode", "hide-annotations", "doc-properties", "about"],
      fullscreen: ["$fullscreen","$navigation", "$split", "text-selection", "pan", "$zoom", "download", "save", "print"]
  };
    viewer.applyToolbarLayout();  
    //viewer.addAnnotationEditorPanel();
    //viewer.addFormEditorPanel();
    //viewer.open("testds.pdf");
     //viewer.addFormEditorPanel();
    viewer.layoutMode = 4;
  return viewer;
}