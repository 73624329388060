import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';

import styles from './select.module.scss';
import { useEventListener } from '../../../Hooks/hooks';

const Select = (props) => {
    const { title, loading, error, items, type, selectref, size, selectedItem, setSelectedItem, selectedItems, setSelectedItems } = props;

    const [showItems, setShowItems] = useState(false);
    const [manualtext, setManualtext] = useState('')
    const [filteredItems, setFilteredItems] = useState([]);

    useEffect(() => setFilteredItems([...items]), [items])

    const filterItems = (e, filterOnly) => {
        const filtered = e.target && e.target.value ? items.filter(i => i.toLowerCase().includes(e.target.value.toLowerCase())) : [...items];
        setFilteredItems(...[filtered]);

        if (!filterOnly) e.target && e.target.value ? setManualtext(e.target.value) : setManualtext('');
    }
    const handleOutsideClick = e => {
        if (selectref && selectref.current && !selectref.current.contains(e.target)) setShowItems(false);
    }
    const handleCheckbox = (e, value) => {
        const currentItems = [...selectedItems];
        if (e.target.checked) currentItems.push(value) 
        else {
            const index = currentItems.findIndex(c => c === value);
            currentItems.splice(index, 1);
        } 
        setSelectedItems([...currentItems]);
        let displayvalue = '';
        currentItems.forEach((i, index) => {
            if (index > 3 || (index > 1 && size === 'small')) return;
            if (index > 1 || (index > 0 && size === 'small')) { displayvalue += '...'; return; }
            currentItems.length === index + 1 ? displayvalue += `${i}` : displayvalue += `${i}, `;
        })
        
        if(value==='Select All') 
        {
            if(e.target.checked)
            {
                filteredItems.forEach((l, index) => {
                    if(!currentItems.includes(l)) currentItems.push(l);
                })
            }
            else
                currentItems.splice(0, currentItems.length);
            setSelectedItems([...currentItems]);
            
            displayvalue = '';
            currentItems.forEach((i, index) => {
                if(i!=="Select All")
                {
                    if (index > 3 || (index > 1 && size === 'small')) return;
                    if (index > 1 || (index > 0 && size === 'small')) { displayvalue += '...'; return; }
                    currentItems.length === index + 1 ? displayvalue += `${i}` : displayvalue += `${i}, `;
                }
            })
        }
        setSelectedItem(displayvalue);
        
    }

    useEventListener('mousedown', handleOutsideClick)
    return (
        <div ref={selectref}>
            <div className={styles.title}>{title}</div>
            <>
                {
                    loading ? 
                    <div className={`${styles.select} ${size === 'small' ? styles['select-small'] : styles['select-normal']}`}>
                        <div className={styles['spinner-section']}>
                            <div className={styles.spinner}></div>
                        </div>
                    </div>
                    : 
                    <div className={`${cx(styles.select, {[styles.disabled]: error})} ${size === 'small' ? styles['select-small'] : styles['select-normal']}`} onClick={() => setShowItems(!showItems)}>
                        {
                            type === 'typeahead' ?
                            <div className={styles.label}><input type='text' placeholder={`Select ${title}`} value={manualtext} onChange={e => filterItems(e, false)} /></div>
                            :
                            <div className={styles.label}>{selectedItem || `Select ${title}`}</div>
                        }
                        <div className={styles.arrow}></div>
                    </div>
                }
            </>
            {
                showItems?
                    <ul className={`${styles.items} ${size === 'small' ? styles['items-small'] : styles['items-normal']}`} style={{maxHeight: props.ddHeight}}>
                        {filteredItems.map(i => {
                            return (
                                type === 'multi' ?
                                    <li key={i}>
                                        <div>
                                            <label className={styles['checkbox-container']}>{i}
                                                <input type='checkbox' checked={selectedItems.includes(i)} onChange={e => handleCheckbox(e, i)} />
                                                <span className={styles['checkbox']} />
                                            </label>
                                        </div>
                                    </li>
                                :
                                    <li key={i} onClick={() => {setSelectedItem(i); setManualtext(i); filterItems('', true); setShowItems(false);}}><div>{i}</div></li>
                            )
                        })}
                    </ul>
                : null
            }
        </div>
    )
}

export default Select;