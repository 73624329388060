import React, { createContext, useState } from 'react';

export const HomeContext = createContext();

const HomeContextProvider = (props) => {
    const [selectedPinManualCode, setSelectedPinManualCode] = useState(null);
    const [showGrid, setShowGrid] = useState(true);
    const [selectedAlertManualCode, setSelectedAlertManualCode] = useState(null);

    return (
        <HomeContext.Provider value={{selectedPinManualCode, setSelectedPinManualCode, showGrid, setShowGrid, selectedAlertManualCode, setSelectedAlertManualCode}}>
            {props.children}
        </HomeContext.Provider>
    )
}
export default HomeContextProvider;