import React from 'react';

import styles from './pagetitle.module.scss';

const PageTitle = (props) => {
    return (
        <div className={styles.title}>{props.title}</div>
    )
}

export default PageTitle;