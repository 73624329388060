import { gql } from '@apollo/client';

export const GET_EMP_MANUALS = gql`
    query Manuals($employeeId: String!) {
        manuals {
            getManualsForEmployee(employeeId: $employeeId) {
                categoryName
                manualName
                isHtml
                isSearchEnabled
                isDocumentCollection
                fleetManuals {
                    manualCode
                    fleetType
                    hasPins
                    hasAlerts
                    revDate
                    revision
                    uRL
                    isF117Manual
                }
                vendorManuals{
                    manualCode
                    vendorName
                    revDate
                    uRL
                }
                engineManuals{
                    manualCode
                    engineType
                    revDate
                    uRL
                }
            }
        }
        damageNotice {
            damageNotice {
                currentSafetyRecord
                damageStation
                previousSafetyRecord
                totalDamages
            }
        }
    }
`;
export const GET_CONFIG_DRAWINGS = gql`
    query Manuals($searchParams: [SearchParamsInputType]!, $manualCode: String!, $isPreview: Boolean) {
        manuals {
            getManualSearch(
                manualCode: $manualCode
                searchParams: $searchParams
                isPreview: $isPreview
            ) {
                fileName
                isPinned
                subTitle
                title
            }
        }
    }
`;
export const GET_EMP_PINS = gql`
    query Manuals($employeeId: String!, $manualCode: String!) {
        manuals {
            getPinnedDocuments (employeeId: $employeeId, manualCode: $manualCode) {
                title
                subTitle
                fileName
            }
        }
    }
`;
export const GET_MANUAL_PINS = gql`
    query GetManualPins {
        pins @client {
            manualCode
            fileName
            title
            subTitle
        }
    }
`;
export const GET_CURRENT_SEARCH = gql`
    query GetCurrentSearch {
        search @client {
            categoryName
            manualName
            isHtml
            isSearchEnabled
            isDocumentCollection
            fleetManuals {
                manualCode
                fleetType
                hasPins
                hasAlerts
                revDate
                revision
                uRL
                isF117Manual
            }
            vendorManuals{
                manualCode
                vendorName
                revDate
                uRL
                isF117Manual
            }
            engineManuals{
                manualCode
                engineType
                revDate
                uRL
                isF117Manual
            }
        }
    }
`;
export const GET_RECENT_MANUALS = gql`
    query GetRecentManuals {
        recent @client {
            manualCode
        }
    }
`;
export const GET_FAVORITE_MANUALS = gql`
    query GetFavoriteManuals {
        favorites @client {
            manualCode
        }
    }
`;
export const GET_ALL_MANUALS = gql`
    query GetAllManuals {
        localmanuals @client {
            id
            title 
            info { 
                rev 
                type 
                fleet
                url
            } 
        }
    }
`;
export const GET_CURRENT_MANUAL = gql`
    query GetCurrentManual {
        manual @client {
            categoryName
            manualName
            manualCode
            hasAlerts
            revDate
            revNumber
            typeInfo
            fleetType
            vendorName
            engineType
            uRL
        }
    }
`;
export const GET_TABLE_CONTENTS = gql`
    query GetTableContents {
        tablecontents @client {
            title
            sections {
                title
                subSections {
                    title
                    docs {
                        title
                        subTitle
                        url
                    }
                }
            }
        }
    }
`;
export const GET_SEARCH_FIELDS = gql`
    query Manuals($manualCode: String!) {
        manuals {
            getManualSearchParam (manualCode: $manualCode) {
                order
                paramName
                paramType
            }
        }
    }
`;
export const GET_ADVANCE_MANUAL_SEARCH = gql`
    query Manuals( $searchParams: String!, $manualCode: [String]!) {
        manuals {
            advanceManualSearch(
                searchParams: $searchParams
                manualCode: $manualCode
            ) {
                advanceSearchDocuments {
                    count
                    fileName
                    manualCode
                    title
                  }
                  manualCode
                  manualName
                  searchCount
                  type
                  totalResultCount
            }
        }
    }
`;
export const GET_MANUAL_VERSION_ACTIVITY = gql`
query ManualVersionActivity($manualCode :String, $isPreview : Boolean){
    manualVersionActivity{
      latestRefreshDate(manualCode: $manualCode, isPreview: $isPreview){
        manualName
        modifiedDate
        modifieddBy
        manualVersionStateId
      }
    }
   }
`